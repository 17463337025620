'use client'

import styled from 'styled-components'

import { Button, isUppercase } from '@syconium/little-miss-figgy'
import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import {
  TypeStyle,
  TypeStyleCssFunctionProps,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'

import { gql } from '../../../../../__generated__/graphql/catalog'
import { StyledLinkFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { ITrackEvent } from '../../../../../lib/analytics/IProps'
import { NextLink } from '../../../navigation/NextLink'

export const styledLinkQueryFragment = gql(`
  fragment StyledLinkFragment on StyledLink {
    __typename  
    id
    text
    url
    linkStyle
  }
`)

export type ContentfulStyledLinkProps = StyledLinkFragmentFragment &
  TypeStyleCssFunctionProps & {
    className?: string
    analyticsContext?: ITrackEvent
  }

export const ContentfulStyledLink = styled(
  ({ className, url, text, analyticsContext, linkStyle }: ContentfulStyledLinkProps) => {
    return (
      <>
        {linkStyle === 'link' && (
          <TextLink
            as={NextLink}
            className={className}
            href={url}
            variant='underline'
            {...analyticsContext}
          >
            {text}
          </TextLink>
        )}
        {linkStyle === 'button' && (
          <Button
            as={NextLink}
            href={url}
            className={className}
            allCaps={isUppercase(text)}
            {...analyticsContext}
          >
            {text}
          </Button>
        )}
      </>
    )
  }
)`
  ${TypeStyle.css.headlineExtraExtraSmall}
`
