'use client'

import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, Text } from '@contentful/rich-text-types'
import { useMemo } from 'react'
import styled from 'styled-components'

import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import { TypeStyle } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { isUppercase } from '@syconium/little-miss-figgy/dist/lib/utils/isUppercase'

import { gql } from '../../../../../__generated__/graphql/catalog'
import { RichTextSectionFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { NextLink } from '../../../navigation/NextLink'

import { ContentfulStyledImage } from './ContentfulStyledImage.client'
import { ContentfulStyledText } from './ContentfulStyledText.client'

export const richTextQueryFragment = gql(`
  fragment RichTextSectionFragment on RichTextSection {
    __typename  
    id
    content
  }
`)

export type ContentfulRichTextProps = Omit<RichTextSectionFragmentFragment, '__typename' | 'id'> & {
  className?: string
}

const CenteredImage = styled(ContentfulStyledImage)`
  margin-left: auto;
  margin-right: auto;
`

const LinkContent = styled.span`
  > u {
    text-decoration: inherit;
  }
`

const Content = styled.div`
  li {
    padding-inline-start: 24px;
    position: relative;

    &::before {
      background-color: ${o => o.theme.color.text.primary.on.background};
      border-radius: 50%;
      content: '';
      height: 2px;
      margin-inline-start: -15px;
      position: absolute;
      top: 11px;
      transform: translate(0px, -50%);
      width: 2px;
    }
  }
`

export const ContentfulRichText = ({ className, content }: ContentfulRichTextProps) => {
  const renderOptions: Options = useMemo(
    () => ({
      renderNode: {
        'styled-text': node => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const props: any = node.data
          return <ContentfulStyledText {...props} />
        },
        'styled-image': node => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const props: any = node.data
          const widths = props.maxWidthDesktop
            ? {
                unit: 'px',
                md: props.maxWidthDesktop,
                sm: props.maxWidthMobile ?? props.maxWidthDesktop,
              }
            : {
                unit: 'vw',
                md: 100,
                sm: 100,
              }
          return <CenteredImage {...props} widths={widths} />
        },
        [BLOCKS.HEADING_1]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineMedium
              color='primary'
              asTag='h2'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '12px', marginTop: '24px' }}
            >
              {children}
            </TypeStyle.HeadlineMedium>
          )
        },
        [BLOCKS.HEADING_2]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineSmall
              color='primary'
              asTag='h2'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '12px', marginTop: '20px' }}
            >
              {children}
            </TypeStyle.HeadlineSmall>
          )
        },
        [BLOCKS.HEADING_3]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineExtraSmall
              color='primary'
              asTag='h3'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '12px', marginTop: '16px' }}
            >
              {children}
            </TypeStyle.HeadlineExtraSmall>
          )
        },
        [BLOCKS.HEADING_4]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineExtraExtraSmall
              color='primary'
              asTag='h4'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '8px', marginTop: '8px' }}
            >
              {children}
            </TypeStyle.HeadlineExtraExtraSmall>
          )
        },
        [BLOCKS.HEADING_5]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineExtraExtraSmall
              color='primary'
              asTag='h5'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '8px', marginTop: '8px' }}
            >
              {children}
            </TypeStyle.HeadlineExtraExtraSmall>
          )
        },
        [BLOCKS.HEADING_6]: (node, children) => {
          const textContent = (node.content[0] as Text | undefined)?.value
          return (
            <TypeStyle.HeadlineExtraExtraSmall
              color='primary'
              asTag='h6'
              isUppercase={!!textContent && isUppercase(textContent)}
              style={{ marginBottom: '8px', marginTop: '8px' }}
            >
              {children}
            </TypeStyle.HeadlineExtraExtraSmall>
          )
        },
        [BLOCKS.PARAGRAPH]: (_node, children) => {
          return (
            <TypeStyle.BodyDefault asTag='p' color='primary' style={{ marginBottom: '8px' }}>
              {children}
            </TypeStyle.BodyDefault>
          )
        },
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <TextLink as={NextLink} href={node.data.uri} variant='underline'>
              <LinkContent>{children}</LinkContent>
            </TextLink>
          )
        },
      },
    }),
    []
  )

  return (
    <Content className={className}>{documentToReactComponents(content, renderOptions)}</Content>
  )
}
