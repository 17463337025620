'use client'

import { createContext, useContext } from 'react'

import { generateContentfulPageSectionContext } from './ContentfulPageSectionProvider.server'

type ContentfulPageSectionContextInput = Parameters<typeof generateContentfulPageSectionContext>[0]

type ContentfulPageSectionContextOutput = {
  pageSectionIndex: number
  pageSectionAnalyticsName: string
  pageSectionDisplayName: string | undefined
}

const ContentfulPageSectionContext = createContext<ContentfulPageSectionContextInput | undefined>(
  undefined
)

type ContentfulPageSectionProviderProps = ContentfulPageSectionContextInput & {
  children?: React.ReactNode
}

export const ContentfulPageSectionProvider = ({
  children,
  ...valueProp
}: ContentfulPageSectionProviderProps) => {
  const sorroundingValue = useContentfulPageSectionContext()
  const value = {
    ...sorroundingValue,
    ...valueProp,
  }
  return (
    <ContentfulPageSectionContext.Provider value={value}>
      {children}
    </ContentfulPageSectionContext.Provider>
  )
}

export const useContentfulPageSectionContext = (): ContentfulPageSectionContextOutput => {
  const pageSectionContext = useContext(ContentfulPageSectionContext)
  return generateContentfulPageSectionContext(pageSectionContext)
}
