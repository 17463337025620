'use client'

import { ComponentProps, useMemo } from 'react'

import { OpenVideoModalTextButton } from '@syconium/little-miss-figgy/dist/components/OpenVideoModalTextButton'

import { PlayButton } from '../../../lib/shared-styles'
import { NextDialogProvider, NextDialogTrigger } from '../dialogs/NextDialog.client'
import { NextVideoDialog } from '../dialogs/NextVideoDialog.client'

type WatchVideoDialogProps = {
  videoId: string
  videoPlatform: 'vimeo' | 'youtube'
  text?: string | undefined | null
} & Omit<ComponentProps<'button'>, 'children' | 'text' | 'onClick'> & {
    render?: ComponentProps<typeof NextDialogTrigger>['render']
  }

export const WatchVideoDialog = ({
  videoId,
  videoPlatform,
  text,
  render: renderProp,
  ...buttonPassthroughProps
}: WatchVideoDialogProps) => {
  const defaultRender = useMemo<NonNullable<typeof renderProp>>(() => {
    return htmlProps => {
      return text ? (
        <OpenVideoModalTextButton text={text} {...htmlProps} {...buttonPassthroughProps} />
      ) : (
        <PlayButton {...htmlProps} {...buttonPassthroughProps} />
      )
    }
  }, [buttonPassthroughProps, text])

  return (
    <NextDialogProvider>
      <NextDialogTrigger render={renderProp ?? defaultRender} />
      <NextVideoDialog videoId={videoId} videoPlatform={videoPlatform} />
    </NextDialogProvider>
  )
}
