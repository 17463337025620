'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import {
  ResponsiveImage,
  ResponsiveImageWrapper,
} from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { ContentfulStyledImageWrapper } from '../components/ContentfulStyledImage.client'

const getContentAlignment = (textPlacementMobile: string) => {
  if (textPlacementMobile === 'below') return 'flex-start'
  if (textPlacementMobile === 'center') return 'center'
  return 'flex-end'
}

export const ContentfulPageBannerSectionBackground = styled.div<{
  $backgroundColorDesktop: string | undefined | null
  $backgroundColorMobile: string | undefined | null
  $textPlacementDesktop: string | undefined | null
  $textPlacementMobile: string | undefined | null
}>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${o => getContentAlignment(o.$textPlacementMobile ?? 'bottom')};
  position: relative;

  ${untilMd} {
    background-color: ${o => o.$backgroundColorMobile};
  }

  ${fromMd} {
    background-color: ${o => o.$backgroundColorDesktop};
    flex-direction: ${o => (o.$textPlacementDesktop === 'left' ? 'row-reverse' : 'row')};
    justify-content: ${o => o.$textPlacementDesktop === 'center' && 'center'};
  }
`

export const ContentfulPageBannerSectionContent = styled.div<{
  $textPlacementDesktop: string | undefined | null
  $textPlacementMobile: string | undefined | null
  $endsInLink: boolean
}>`
  box-sizing: border-box;

  padding: ${o => (o.$textPlacementMobile === 'bottom' ? '0 16px 73px 16px' : '0 16px')};

  ${untilMd} {
    padding-top: ${o => o.$textPlacementMobile === 'below' && '48px'};
    width: 100%;
    position: ${o => o.$textPlacementMobile !== 'below' && 'absolute'};
    padding-bottom: ${o =>
      o.$textPlacementMobile === 'bottom'
        ? '73px'
        : o.$textPlacementMobile === 'below' && o.$endsInLink
        ? '24px'
        : o.$textPlacementMobile === 'below'
        ? '48px'
        : null};
  }

  ${fromMd} {
    position: ${o => (o.$textPlacementDesktop === 'center' ? 'absolute' : 'relative')};
    padding: 0 24px;
  }

  width: ${o => (o.$textPlacementDesktop === 'center' ? '100%' : '50%')};

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

const aspectRatioMobileShort = 320 / 253
const aspectRatioMobileTall = 320 / 486
const aspectRatioDesktopSide = 600 / 475
const aspectRatioDesktopSideFullBleed = 720 / 500
const aspectRatioDesktopCenter = 1200 / 475
const aspectRatioDesktopCenterFullBleed = 1440 / 500

export const ContentfulPageBannerSectionAssetWrapper = styled.div<{
  $isFullBleedDesktop: boolean | undefined | null
  $isTallAssetMobile: boolean | undefined | null
  $overlayColorDesktop: string | undefined | null
  $overlayColorMobile: string | undefined | null
  $textPlacementDesktop: string | undefined | null
  $textPlacementMobile: string | undefined | null
}>`
  display: block;
  overflow: hidden;
  padding-bottom: min(
    ${o =>
      (1 /
        (o.$textPlacementMobile !== 'below' || o.$isTallAssetMobile
          ? aspectRatioMobileTall
          : aspectRatioMobileShort)) *
      100}%,
    ${o => (o.$textPlacementMobile !== 'below' || o.$isTallAssetMobile ? '550px' : '350px')}
  );
  position: relative;
  width: 100%;
  > ${ContentfulStyledImageWrapper} {
    height: 0;
  }
  > ${ContentfulStyledImageWrapper}
    > ${ResponsiveImageWrapper},
    > ${ContentfulStyledImageWrapper}
    > ${ResponsiveImageWrapper}
    > picture
    > img,
  > picture > img,
  > img,
  > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${fromMd} {
    padding-bottom: max(
      min(
        ${o =>
          (1 /
            (o.$textPlacementDesktop === 'center'
              ? o.$isFullBleedDesktop
                ? aspectRatioDesktopCenterFullBleed
                : aspectRatioDesktopCenter
              : (o.$isFullBleedDesktop ? aspectRatioDesktopSideFullBleed : aspectRatioDesktopSide) /
                0.5)) *
          100}%,
        ${o => (o.$isFullBleedDesktop ? '750px' : '700px')}
      ),
      360px
    );
    width: ${o => o.$textPlacementDesktop !== 'center' && '50%'};
  }

  ${o =>
    o.$overlayColorMobile || o.$overlayColorDesktop
      ? `
        &::after {
          background: ${o.$overlayColorMobile};
          bottom: 0;
          content: '';
          left: 0;
          position: absolute;
          right: 0;
          top: 0;

          ${fromMd} {
            background: ${o.$overlayColorDesktop};
            display: ${o.$textPlacementDesktop === 'center' ? 'block' : 'none'};
          }
        }
      `
      : null}
`

export const ContentfulPageBannerSectionAssetOverlayedContent = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2 * 24px); /* 24px gap on each side */
  z-index: ${o => o.theme.zIndex.foregroundContent};
`

export const ContentfulPageBannerSectionHotspot = styled.a`
  background: white;
  border-radius: 36px;
  bottom: 24px;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 24px;
  position: absolute;
  width: 36px;
  z-index: 101;

  & > svg {
    transform: translateY(100%);
  }
`

export const ContentfulPageBannerSectionContentDeprecated = styled.div<{
  $textColorDesktop: string | undefined | null
  $textColorMobile: string | undefined | null
  $textContentMaxWidthDesktop: number | undefined | null
  $textContentMaxWidthMobile: number | undefined | null
  $textPlacementDesktop: string | undefined | null
  $textPlacementMobile: string | undefined | null
  $textAlignMobile: string | undefined | null
  $textAlignDesktop: string | undefined | null
}>`
  box-sizing: border-box;
  color: ${o =>
    o.$textColorMobile ? o.$textColorMobile : o.theme.color.text.primary.on.background};
  letter-spacing: 0.6px;
  margin-top: ${o => o.$textPlacementMobile === 'below' && '40px'};
  padding: ${o => (o.$textPlacementMobile === 'bottom' ? '0 24px 73px 24px' : '0 24px 40px')};
  position: ${o => o.$textPlacementMobile !== 'below' && 'absolute'};
  text-align: ${o => o.$textAlignMobile ?? 'center'};

  ${untilMd} {
    > .configurable-width {
      max-width: ${o => o.$textContentMaxWidthMobile && `${o.$textContentMaxWidthMobile}px`};
    }
    width: 100%;
  }

  ${fromMd} {
    color: ${o =>
      o.$textColorDesktop ? o.$textColorDesktop : o.theme.color.text.primary.on.background};
    line-height: 170%;
    margin-top: 0;
    padding: 24px;
    position: ${o => (o.$textPlacementDesktop === 'center' ? 'absolute' : 'relative')};
    text-align: ${o => o.$textAlignDesktop ?? 'center'};
    width: ${o => o.$textPlacementDesktop !== 'center' && '50%'};

    > .configurable-width {
      max-width: ${o =>
        o.$textContentMaxWidthDesktop
          ? `${o.$textContentMaxWidthDesktop}px`
          : o.$textPlacementDesktop === 'center'
          ? '500px'
          : '384px'};
    }
  }

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

export const ContentfulPageBannerSectionEyebrowDeprecated = styled.h3`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.25em;
  margin-bottom: 16px;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
    margin-top: 0;
  }
`

type ContentfulPageBannerSectionTitleImageDeprecatedProps = {
  className?: string
  $textPlacementMobile: string | undefined | null
  $location: 'text' | 'asset'
  $titleImageMaxWidthDesktop: number | undefined | null
  $titleImageMaxWidthMobile: number | undefined | null
} & Omit<ComponentProps<typeof ResponsiveImage>, 'transparentBackground'>

export const ContentfulPageBannerSectionTitleImageDeprecated = styled(
  (props: ContentfulPageBannerSectionTitleImageDeprecatedProps) => {
    return <ResponsiveImage {...props} transparentBackground />
  }
)`
  display: none;
  margin: 0 auto 16px;
  width: 100%;

  ${untilMd} {
    max-width: ${o => (o.$titleImageMaxWidthMobile ? `${o.$titleImageMaxWidthMobile}px` : '78%')};
  }

  ${fromMd} {
    max-width: ${o => (o.$titleImageMaxWidthDesktop ? `${o.$titleImageMaxWidthDesktop}px` : '50%')};
  }

  ${o => (o.$location === 'asset' && o.$textPlacementMobile === 'below' ? 'display: block' : null)};
  ${o => (o.$location === 'text' && o.$textPlacementMobile !== 'below' ? 'display: block' : null)};
  ${fromMd} {
    display: ${o => (o.$location === 'text' ? 'block' : 'none')};
  }
`

export const ContentfulPageBannerSectionLinksDeprecated = styled.div<{
  $textAlignMobile: string | undefined | null
  $textAlignDesktop: string | undefined | null
}>`
  margin-top: 16px;
  text-align: ${o => o.$textAlignMobile || 'center'};

  ${untilMd} {
    > :nth-child(2) {
      margin-right: ${o => o.$textAlignMobile === 'right' && '0'};
    }

    > :first-child {
      margin-left: ${o => o.$textAlignMobile === 'left' && '0'};
    }
  }

  ${fromMd} {
    margin-top: 26px;
    text-align: ${o => o.$textAlignDesktop || 'center'};

    > :first-child {
      margin-left: ${o => o.$textAlignDesktop === 'left' && '0'};
      margin-right: ${o => o.$textAlignDesktop === 'right' && '0'};
    }

    > :nth-child(2) {
      margin-right: ${o => o.$textAlignDesktop === 'right' && '0'};
    }
  }

  a {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 23px;
    margin: 0 12px 22px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      border-bottom: 2px solid currentColor;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`

export const ContentfulPageBannerSectionButtonsDeprecated = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  gap: 12px;
`

export const ContentfulPageBannerSectionVideoDialogButtonWrapper = styled.div<{
  $textPlacementMobile: string | null | undefined
  $location: 'text' | 'asset'
  $spacing: 'more' | 'less'
}>`
  display: none;
  ${o => (o.$location === 'asset' && o.$textPlacementMobile === 'below' ? 'display: flex' : null)};
  ${o => (o.$location === 'text' && o.$textPlacementMobile !== 'below' ? 'display: flex' : null)};

  ${fromMd} {
    display: none;
    ${o => (o.$location === 'text' ? 'display: flex' : null)};
  }

  justify-content: center;

  margin-left: auto;
  margin-right: auto;
  margin: ${o => (o.$spacing === 'more' ? '22px auto 8px' : '16px auto 0px')};
  ${fromMd} {
    margin-top: ${o => (o.$spacing === 'more' ? '32px' : '24px')};
  }
`
