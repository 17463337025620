'use client'

import styled from 'styled-components'

import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

export const HideChildrenOn = styled.div<{
  $mobile?: boolean
  $desktop?: boolean
}>`
  ${untilMd} {
    display: ${o => o.$mobile && 'none'};
  }

  ${fromMd} {
    display: ${o => o.$desktop && 'none'};
  }
`

export const DisplayOnlyOn = styled.div<{
  mobile?: boolean
  desktop?: boolean
}>`
  ${untilMd} {
    display: ${o => o.desktop && 'none'};
  }

  ${fromMd} {
    display: ${o => o.mobile && 'none'};
  }
`

export const PageWidthContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 16px;
  width: 100%;

  ${fromMd} {
    padding: 0 24px;
    padding: 0 11.1%;
  }
`

export const GrayHorizontalBar = styled.div`
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stone};
  margin: 35px 0;
`

export const PlayButton = styled.button`
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 10px -5px black;
  height: 44px;
  position: relative;
  width: 44px;

  &::after {
    content: '';
    display: block;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid black;
    height: 0;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-36%, -50%);
    width: 0;
  }
`
