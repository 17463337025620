import { type PageSection } from '../../../../__generated__/graphql/catalog/graphql'

function camelToHyphenated(input: string) {
  return input
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .filter(i => i !== '')
    .join('-')
    .toLowerCase()
}

export const getContentfulPageSectionAnalyticsName = (
  typename: NonNullable<PageSection['__typename']>
) => {
  const hyphenatedTypename = camelToHyphenated(typename)
  switch (typename) {
    case 'AccordionView':
      return 'accordion-section'
    default:
      return hyphenatedTypename
  }
}

export const generateContentfulPageSectionContext = (props?: {
  pageSectionIndex?: number
  pageSectionTypename?: NonNullable<PageSection['__typename']>
  pageSectionDisplayName?: string
}) => {
  return {
    pageSectionIndex: props?.pageSectionIndex ?? -1,
    pageSectionAnalyticsName: props?.pageSectionTypename
      ? getContentfulPageSectionAnalyticsName(props.pageSectionTypename)
      : 'section-not-named',
    pageSectionDisplayName: props?.pageSectionDisplayName,
  }
}
