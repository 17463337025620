'use client'

export const isActionableElement = (
  input: Element | EventTarget | null | undefined
): input is Element => {
  const element = input instanceof Element ? input : undefined
  if (!element) return false
  return !!(
    ['BUTTON', 'A', 'SELECT', 'INPUT'].includes(element.tagName) ||
    element.closest('A, BUTTON, SELECT, INPUT')
  )
}
