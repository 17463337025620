'use client'

import styled from 'styled-components'

import {
  AccordionBody,
  AccordionHeader,
} from '@syconium/little-miss-figgy/dist/components/Accordion'
import { TypeStyle } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/themes/default/breakpoints'

const StyledWrapper = styled.div<{ $maxWidth?: number | null }>`
  display: flex;
  margin: auto;
  width: 100%;
  max-width: ${o => o.$maxWidth && `${o.$maxWidth}px`};
  border-top: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
  border-bottom: 1px solid ${o => o.theme.color.stroke.neutral.border.decoration.on.background};
`

type WrapperProps = {
  children?: React.ReactNode
  className?: string
  maxWidth?: number | null
}

export const Wrapper = ({ maxWidth, ...rest }: WrapperProps) => {
  return <StyledWrapper {...rest} $maxWidth={maxWidth} />
}

export const Header = styled(AccordionHeader)`
  ${untilMd}, ${fromMd} {
    padding: ${o => o.theme.spacing(6)} 0;
  }
`

export const Body = styled(AccordionBody)`
  padding-left: ${o => o.theme.spacing(4)};
  padding-right: ${o => o.theme.spacing(4)};
`

export const Title = styled(TypeStyle.HeadlineExtraExtraSmall)`
  text-align: start;
  font-weight: bold;
`
