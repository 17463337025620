'use client'

import { ComponentProps } from 'react'
import styled from 'styled-components'

import { ResponsiveImageWithSkeletonWrapper } from '@syconium/little-miss-figgy'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { gql } from '../../../../../__generated__/graphql/catalog'
import { StyledImageFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'

export const styledImageQueryFragment = gql(`
  fragment StyledImageFragment on StyledImage {
    __typename  
    id
    imageMobile
    imageDesktop
    altText
    maxWidthMobile
    maxWidthDesktop
    aspectRatioMobile
    aspectRatioDesktop
    bottomMargin
    altTextMobile
    altTextDesktop
    imageThemeDesktop
    imageThemeMobile
  }
`)

type ContentfulStyledImageProps = StyledImageFragmentFragment & {
  className?: string
  widths: ComponentProps<typeof ResponsiveImageWithSkeletonWrapper>['widths']
  loading: ComponentProps<typeof ResponsiveImageWithSkeletonWrapper>['loading']
  renderPreloadLink?: ComponentProps<typeof ResponsiveImageWithSkeletonWrapper>['renderPreloadLink']
  fetchPriority?: ComponentProps<typeof ResponsiveImageWithSkeletonWrapper>['fetchPriority']
  transparentBackground?: ComponentProps<
    typeof ResponsiveImageWithSkeletonWrapper
  >['transparentBackground']
}

export const ContentfulStyledImageWrapper = styled.div<{
  $maxWidthMobile?: number | undefined | null
  $maxWidthDesktop?: number | undefined | null
  $marginBottom?: number | undefined | null
}>`
  margin-bottom: ${o => (o.$marginBottom ? o.$marginBottom + 'px' : 'unset')};

  ${untilMd} {
    max-width: ${o => (o.$maxWidthMobile ? o.$maxWidthMobile + 'px' : 'unset')};
  }

  ${fromMd} {
    max-width: ${o => (o.$maxWidthDesktop ? o.$maxWidthDesktop + 'px' : 'unset')};
  }
`

export const ContentfulStyledImage = ({
  imageMobile,
  imageDesktop,
  maxWidthMobile,
  maxWidthDesktop,
  aspectRatioMobile,
  aspectRatioDesktop,
  altText,
  bottomMargin,
  widths,
  transparentBackground,
  className,
}: ContentfulStyledImageProps) => {
  const imgSrc = imageDesktop ?? imageMobile

  if (!imgSrc) return null

  return (
    <ContentfulStyledImageWrapper
      $maxWidthMobile={maxWidthMobile}
      $maxWidthDesktop={maxWidthDesktop}
      $marginBottom={bottomMargin}
      className={className}
    >
      <ResponsiveImageWithSkeletonWrapper
        src={imgSrc}
        aspectRatios={{
          sm: aspectRatioMobile,
          md: aspectRatioDesktop,
        }}
        loading='lazy'
        alt={altText}
        srcs={{
          sm: imageMobile ?? imgSrc,
          md: imageDesktop ?? imgSrc,
        }}
        widths={widths}
        transparentBackground={transparentBackground}
      />
    </ContentfulStyledImageWrapper>
  )
}
