'use client'

import VimeoIframeController from '@vimeo/player'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { type ButtonSize } from '@syconium/little-miss-figgy'
import { userPrefersReducedMotion } from '@syconium/little-miss-figgy/dist/lib/utils/prefersReducedMotion'

import { RenderOnceInViewportVisibilityTarget } from '../../../app/_components/performance/RenderOnceInViewport.client'
import { StyledVideoButtonsWrapper } from '../../../components/pages/products/ProductPage/ImageZoom/styles'

import {
  NonInteractiveThumbnailContainer,
  StyledPauseButton,
  VimeoIframe,
  VimeoWrapper,
} from './styles'

type VimeoVideoProps = {
  overridePlayState?: 'play' | 'pause'
  autoplay?: boolean
  lazy: boolean
  thumbnail?: React.ReactNode
  title?: string
  videoSrc: string
  playText: string
  pauseText: string
  position?: string
  isInteractive?: boolean
  includePauseButton?: boolean
  pauseButtonPosition?: 'bottom' | 'top'
  objectPosition?: 'center' | 'top'
  pauseButtonSize?: ButtonSize
  videoRefCallback?: (element: HTMLIFrameElement | null) => void
  videoElement?: HTMLIFrameElement | null
}

export const VimeoVideo: React.FC<VimeoVideoProps> = ({
  overridePlayState,
  autoplay: autoplayProp,
  isInteractive = true,
  lazy = false,
  thumbnail,
  title,
  playText,
  pauseText,
  videoSrc: videoSrcProp,
  position,
  includePauseButton = false,
  pauseButtonPosition = 'bottom',
  objectPosition = 'center',
  pauseButtonSize,
  videoRefCallback,
  videoElement,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '0px',
    triggerOnce: true,
    fallbackInView: true,
  })

  const [isPaused, setIsPaused] = useState<boolean>(() => {
    return overridePlayState === 'pause' || !autoplayProp || userPrefersReducedMotion
  })

  const [autoplay] = useState<boolean>(!isPaused)

  const play = useCallback(() => {
    if (typeof iframeRef.current?.contentWindow?.postMessage === 'function') {
      iframeRef.current.contentWindow.postMessage({ method: 'play' }, 'https://player.vimeo.com')
    }
  }, [iframeRef])

  const pause = useCallback(() => {
    if (typeof iframeRef.current?.contentWindow?.postMessage === 'function') {
      iframeRef.current.contentWindow.postMessage({ method: 'pause' }, 'https://player.vimeo.com')
    }
  }, [iframeRef])

  const togglePause = useCallback(() => {
    if (isPaused) {
      play()
      setIsPaused(false)
    } else {
      pause()
      setIsPaused(true)
    }
  }, [isPaused, pause, play])

  const videoSrc = useMemo(() => {
    if (!lazy) return videoSrcProp
    if (inView) return videoSrcProp
    return undefined
  }, [inView, lazy, videoSrcProp])

  const vimeoIframeControllerRef = useRef<VimeoIframeController | null>(null)
  const [isReady, setIsReady] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(() => {
    return 16 / 9
  })
  useEffect(() => {
    let isMostRecentExecution = true
    setIsReady(false)
    const controller = vimeoIframeControllerRef.current
      ? vimeoIframeControllerRef.current
      : videoElement?.src
      ? new VimeoIframeController(videoElement)
      : iframeRef.current?.src
      ? new VimeoIframeController(iframeRef.current)
      : undefined

    if (!controller) return

    const checkMediaAspectRatio = () => {
      // Until we have switched to using a video tag, there is some nuance with Vimeo iFrames.
      // Vimeo does not seem to allow the video tag within the iframe to cover the iframe element.
      // Instead of acting like `object-fit: cover`, it basically functions like `object-fit: contain`
      //
      // Since the iframe can be a different size and aspect ratio as the video content contained within
      // it. We can end up with gaps above and below the video but inside the iframe, which will give us
      // glimpses to FIGS placeholder images behind the iframe itself.
      //
      // Wait to mark the video as ready until we can measure its size to get its aspect ratio and
      // then force the iframe to match the video's aspect ratio, basically creating `object-fit: cover`
      // that we desire.
      controller.ready().then(() => {
        if (!isMostRecentExecution) return
        Promise.all([controller.getVideoWidth(), controller.getVideoHeight()])
          .then(dimensions => {
            if (isMostRecentExecution) {
              const [initialWidth, initialHeight] = dimensions
              setAspectRatio(initialWidth / initialHeight)
            }
          })
          .catch(error => {
            if (isMostRecentExecution) {
              console.error(error)
            }
          })
          .finally(() => {
            if (isMostRecentExecution) {
              setIsReady(true)
            }
          })
      })
    }

    checkMediaAspectRatio()
    controller.on('loaded', checkMediaAspectRatio)

    return () => {
      isMostRecentExecution = false
      controller.off('loaded', checkMediaAspectRatio)

      vimeoIframeControllerRef.current = null
      controller?.destroy()
    }
  }, [videoSrc, videoElement, iframeRef])

  useEffect(() => {
    if (overridePlayState && isReady) {
      if (overridePlayState === 'pause') {
        pause()
      } else {
        play()
      }
    }
  }, [isReady, overridePlayState, pause, play])

  return (
    <>
      {isInteractive ? (
        <>{thumbnail}</>
      ) : (
        <NonInteractiveThumbnailContainer position={position}>
          {thumbnail}
        </NonInteractiveThumbnailContainer>
      )}

      <VimeoWrapper>
        {includePauseButton && !overridePlayState && isReady ? (
          <StyledVideoButtonsWrapper
            $position={pauseButtonPosition}
            $smallButtons={pauseButtonSize === 'small'}
          >
            <StyledPauseButton
              onClick={togglePause}
              paused={isPaused}
              pauseText={pauseText}
              playText={playText}
              size={pauseButtonSize}
            />
          </StyledVideoButtonsWrapper>
        ) : null}

        <RenderOnceInViewportVisibilityTarget ref={ref} />

        {videoSrc ? (
          <VimeoIframe
            key={videoSrc}
            data-ot-ignore
            ref={videoRefCallback ? videoRefCallback : iframeRef}
            $aspectRatio={aspectRatio}
            $isVisible={isReady}
            $isInteractive={isInteractive}
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen
            frameBorder='0'
            src={`${videoSrc}?autoplay=${autoplay}&loop=1&title=0&byline=0&portrait=0&controls=0&muted=1&autopause=0&dnt=1`}
            title={title}
            $objectPosition={objectPosition}
            tabIndex={-1}
          />
        ) : null}
      </VimeoWrapper>
    </>
  )
}

export default VimeoVideo
