'use client'

import { Fragment, useCallback, useState } from 'react'
import styled from 'styled-components'

import { Instagram, ResponsiveImage, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

import { PageTestimonialSectionFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { trackEvent } from '../../../../../lib/analytics'
import { useTranslation } from '../../../../_providers/TranslationProvider.client'
import { PageSection, PageSectionContent } from '../../PageSection.client'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'

const PageSectionWithBackgroundColor = styled(PageSection)<{
  $backgroundColor?: string
}>`
  background: ${o => o.$backgroundColor};
`

const TestimonailSectionTextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  text-align: center;

  ${fromMd} {
    margin-bottom: 64px;
  }
`

const TestimonailSectionTitle = styled.h2`
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
  }
`

const TestimonailSectionSubhead = styled(TypeStyle.BodySmall)`
  margin-bottom: 32px;
  margin-top: 16px;
  max-width: 432px;
`

const TestimonailSectionCarousel = styled.div`
  position: relative;
`

const TestimonailSectionCarouselArrowContainer = styled.div<{
  $background?: string
}>`
  background-color: ${o => (o.$background ? o.$background : 'transparent')};
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(calc(100% + 192px));

  ${fromMd} {
    display: flex;
  }
`

const TestimonailSectionCarouselArrow = styled.button`
  border: none;
  height: 55px;
  padding: 8px;
  width: 55px;

  svg {
    transition: opacity 0.3s;
  }

  &:disabled svg {
    opacity: 0.5;
  }
`

const TestimonailSectionCarouselArrowIcon: React.FC<{ scaleX?: number }> = ({ scaleX }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      style={{
        transform: `scaleX(${scaleX})`,
      }}
    >
      <path
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeWidth='2'
        d='M10 19l-7-7m0 0l7-7m-7 7h18'
      />
    </svg>
  )
}

const Testimonials = styled.ul`
  list-style: none;
  margin: 0;
  min-height: calc(80vw + 120px);
  padding: 0;
  position: relative;

  ${fromMd} {
    height: 574px;
    min-height: 0;
  }
`

const Testimonial = styled.li<{
  $before?: boolean
  $current?: boolean
  $next?: boolean
  $after?: boolean
}>`
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.3s;

  &:only-child {
    position: relative;
  }

  ${o =>
    (o.$current || o.$next) &&
    `
    pointer-events: initial;
    opacity: 1;
  `}

  ${fromMd} {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`

const TestimonialImageContainer = styled.div<{
  $before?: boolean
  $current?: boolean
  $next?: boolean
  $after?: boolean
  $onlySlide?: boolean
}>`
  clip-path: inset(0 0 0 0);
  max-width: ${o => (o.$onlySlide ? '100%' : '70vw')};
  transform: translateX(0);
  transition:
    transform 0.5s,
    clip-path 0.4s;

  ${o =>
    o.$before &&
    `
    transform: translateX(-50%);
  `}

  ${o =>
    o.$next &&
    `
    transform: translateX(calc(70vw + 16px));
    clip-path: inset(0 15% 41% 0);
  `}

  ${o =>
    o.$after &&
    `
    transform: translateX(calc(70vw + 64px));
    clip-path: inset(0 15% 41% 0);
    transition-delay: 0.3s;
  `}

  ${fromMd} {
    display: flex;
    flex-shrink: 0;
    height: 574px;
    justify-content: center;
    max-width: none;
    order: 2;
    width: 574px;

    ${o =>
      o.$next &&
      `
      transform: translateX(calc(100% + 16px));
    `}

    ${o =>
      o.$after &&
      `
      transform: translateX(calc(150% + 16px));
    `}
  }
`

const TestimonialImage = styled(ResponsiveImage)<{
  $before?: boolean
  $current?: boolean
  $next?: boolean
  $after?: boolean
}>`
  clip-path: inset(0 0 0 0);
  max-width: 100%;
  object-fit: cover;
  transform-origin: top left;
  transition: transform 0.5s;

  ${o =>
    (o.$after || o.$next) &&
    `
    transform: scale(0.7);
  `}
`

const TestimonialInfo = styled.figure<{
  $before?: boolean
  $current?: boolean
  $next?: boolean
  $after?: boolean
}>`
  margin: 0;
  transition:
    opacity 0.3s,
    transform 0.3s;

  ${o =>
    (o.$before || o.$after || o.$next) &&
    `
    opacity:0;
  `}

  ${o =>
    o.$before &&
    `
    transform: translateX(-10%);
  `}

  ${o =>
    (o.$after || o.$next) &&
    `
    transform: translateX(10%);
  `}

  ${fromMd} {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    left: 0;
    max-width: max(33vw, 400px);
    position: absolute;
    top: 50%;
    transform: translate(min(6rem, 5vw), -50%);
    z-index: 1;

    figcaption,
    blockquote {
      transition: transform 0.4s;
    }

    figcaption {
      transition-delay: 0.01s;
    }

    ${o =>
      o.$before &&
      `
      figcaption {
        transform: translateX(-64px);
      }

      blockquote {
        transform: translateX(-64px);
      }
    `}

    ${o =>
      (o.$after || o.$next) &&
      `
      figcaption {
        transform: translateX(64px);
      }

      blockquote {
        transform: translateX(64px);
      }
    `}
  }
`

const TestimonialQuote = styled.blockquote`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3;
  margin: 20px 0;

  ${fromMd} {
    font-size: 34px;
    line-height: 1.4;
    margin: 40px 0;
  }
`

const TestimonialName = styled.p`
  letter-spacing: 1px;
  margin: 0 0 8px 0;
`

const TestimonialOccupation = styled.p`
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: capitalize;
`

const InstagramWrapper = styled.p`
  align-items: center;
  bottom: 0;
  display: flex;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  text-transform: capitalize;

  svg {
    margin-right: 8px;
  }
`

type ContentfulPageTestimonialSectionProps = PageTestimonialSectionFragmentFragment & {
  backgroundColor?: string
}

export const ContentfulPageTestimonialSection = ({
  title,
  subtitle,
  testimonials,
  backgroundColor,
}: ContentfulPageTestimonialSectionProps) => {
  const {
    testimonials: { nextSlideButtonLabel, prevSlideButtonLabel },
  } = useTranslation()

  const numSlides = testimonials?.length ?? 0
  const lastSlideIndex = numSlides - 1

  const { pageSectionIndex: pageSectionIndex } = useContentfulPageSectionContext()

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)
  const [lock, setLock] = useState(false)
  const [slideIndex, setSlideIndex] = useState(0)

  const getSlideProps = useCallback(
    (index: number) => {
      return {
        $before: slideIndex > index,
        $current: slideIndex === index,
        $next: slideIndex + 1 === index,
        $after: slideIndex + 1 < index,
      }
    },
    [slideIndex]
  )

  const moveSlide = useCallback(
    (indexDelta: number): void => {
      setSlideIndex(Math.min(lastSlideIndex, Math.max(0, slideIndex + indexDelta)))
    },
    [lastSlideIndex, slideIndex]
  )

  const handleTouchStart = useCallback((e: React.TouchEvent): void => {
    setX(e.changedTouches[0]?.screenX ?? 0)
    setY(e.changedTouches[0]?.screenY ?? 0)
  }, [])

  const handleTouchMove = useCallback(
    (e: React.TouchEvent): void => {
      const dx = (e.changedTouches[0]?.screenX ?? 0) - x
      const dy = (e.changedTouches[0]?.screenY ?? 0) - y
      if (Math.abs(dx) < 30 || Math.abs(dy) > Math.abs(dx) || lock) return
      setLock(true)
      moveSlide(dx >= 0 ? -1 : 1)
    },
    [lock, moveSlide, x, y]
  )

  const handleTouchEnd = useCallback((_: React.TouchEvent): void => {
    setLock(false)
  }, [])

  return (
    <PageSectionWithBackgroundColor $backgroundColor={backgroundColor}>
      <PageSectionContent>
        <TestimonailSectionTextContainer>
          <TestimonailSectionTitle>{title}</TestimonailSectionTitle>
          <TestimonailSectionSubhead data-testid='testimonials-carousel-subhead'>
            {subtitle}
          </TestimonailSectionSubhead>
        </TestimonailSectionTextContainer>

        {numSlides > 0 && (
          <TestimonailSectionCarousel
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            <Testimonials>
              {testimonials?.map((testimonial, index) => {
                return (
                  <Fragment key={index}>
                    <Testimonial {...getSlideProps(index)}>
                      <TestimonialImageContainer
                        {...getSlideProps(index)}
                        $onlySlide={numSlides === 1}
                      >
                        <TestimonialImage
                          {...getSlideProps(index)}
                          alt={testimonial.personName}
                          src={testimonial.image}
                          aspectRatios={{
                            sm: null,
                            md: null,
                          }}
                          loading='default'
                          widths={{ unit: 'vw', sm: 100, md: 50 }}
                        />

                        {testimonial.instagram && (
                          <InstagramWrapper>
                            <Instagram /> @{testimonial.instagram}
                          </InstagramWrapper>
                        )}
                      </TestimonialImageContainer>
                      <TestimonialInfo {...getSlideProps(index)}>
                        <TestimonialQuote>{testimonial.quote}</TestimonialQuote>
                        <figcaption>
                          <TestimonialName>{testimonial.personName}</TestimonialName>
                          <TestimonialOccupation>{testimonial.occupation}</TestimonialOccupation>
                        </figcaption>
                      </TestimonialInfo>
                    </Testimonial>
                  </Fragment>
                )
              })}
            </Testimonials>
            {numSlides > 1 && (
              <TestimonailSectionCarouselArrowContainer
                $background={backgroundColor}
                {...trackEvent({
                  category: 'page-testimonial-section',
                  action: 'change testimonial slide',
                  pageSectionIndex,
                  pageSectionName: title ?? undefined,
                })}
              >
                <TestimonailSectionCarouselArrow
                  aria-label={prevSlideButtonLabel}
                  disabled={slideIndex <= 0}
                  onClick={() => moveSlide(-1)}
                >
                  <TestimonailSectionCarouselArrowIcon scaleX={1} />
                </TestimonailSectionCarouselArrow>
                <TestimonailSectionCarouselArrow
                  aria-label={nextSlideButtonLabel}
                  disabled={slideIndex >= lastSlideIndex}
                  onClick={() => moveSlide(1)}
                >
                  <TestimonailSectionCarouselArrowIcon scaleX={-1} />
                </TestimonailSectionCarouselArrow>
              </TestimonailSectionCarouselArrowContainer>
            )}
          </TestimonailSectionCarousel>
        )}
      </PageSectionContent>
    </PageSectionWithBackgroundColor>
  )
}
