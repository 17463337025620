'use client'

import { FC, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import {
  Button,
  MediaAsContents,
  ResponsiveImage,
  TypeStyleHeadlineExtraExtraSmall,
  fromMd,
  typeStyleMap,
} from '@syconium/little-miss-figgy'

import { GetPageInteractiveSlideSectionQuery } from '../../../../../__generated__/graphql/catalog/graphql'
import { trackEvent } from '../../../../../lib/analytics/analytics'
import { NextLink } from '../../../navigation/NextLink'
import { PageSectionContent } from '../../PageSection.client'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'

export const StyledPageSectionContent = styled(PageSectionContent)`
  position: relative;
  height: 720px;
`

export const ContentOverlay = styled.div`
  ${fromMd} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
  }
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: inherit;
  z-index: 2;
`

export const OverlayColor = styled.div<{
  $darkContent: boolean
  $customOpacity: number | undefined
}>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${o =>
    !o.$darkContent
      ? o.theme.color.deprecated.black.primary
      : o.theme.color.deprecated.white.primary};
  opacity: ${o => (o.$customOpacity ? o.$customOpacity : `1`)};
  z-index: 1;
  transition: opacity, 0.6s;
  box-sizing: border-box;
  background-clip: content-box;
  padding: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ImageContainer = styled.div<{ $visible: boolean }>`
  position: absolute;
  padding: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${o => (!o.$visible ? `0` : `1`)};
  transition: opacity 0.6s ease;
`
export const StyledImage = styled(ResponsiveImage)`
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const TabButtonColumn = styled.div`
  display: flex;
  order: 3;
  align-self: center;
  gap: ${o => o.theme.spacing(2)};
  ${fromMd} {
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
    margin: 0 0 0 ${o => o.theme.spacing(10)};
    height: auto;
    width: 24px;
  }
  height: 24px;
  width: auto;
  margin-top: auto;
  margin-bottom: ${o => o.theme.spacing(10)};
`

export const TabSVG = styled.svg`
  max-width: 24px;
  max-height: 24px;
`

export const PauseSVG = styled.svg`
  max-width: 24px;
  max-height: 24px;
  viewbox: 0 0 24px 24px;
  fill: none;
`
export const StyledPath = styled.path<{
  $darkContentMobile: boolean
  $darkContentDesktop: boolean
}>`
  fill-rule: evenodd;
  cliprule: evenodd;
  fill: ${o =>
    o.$darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    fill: ${o =>
      o.$darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
`
export const DashOffsetAnimation = keyframes`
  from {
    stroke-dashoffset: 76px;
  }

  to {
    stroke-dashoffset: 6px;
  }
`

export const AnimatedProgressCircle = styled.circle<{
  $isPaused: boolean
  $darkContentMobile: boolean
  $darkContentDesktop: boolean
}>`
  stroke-dasharray: 76px;
  cx: 12px;
  cy: 12px;
  r: 11px;
  fill: transparent;
  stroke: ${o =>
    o.$darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    stroke: ${o =>
      o.$darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
  stroke-width: 2px;
  animation: ${DashOffsetAnimation} 8s linear;
  animation-play-state: ${o => (o.$isPaused ? 'paused' : 'running')};
`

export const CompleteActiveCircle = styled.circle<{
  $darkContentMobile: boolean
  $darkContentDesktop: boolean
}>`
  stroke-dasharray: 76px;
  cx: 12px;
  cy: 12px;
  r: 11px;
  fill: transparent;
  stroke: ${o =>
    o.$darkContentMobile
      ? o.theme.color.deprecated.obsidian
      : o.theme.color.deprecated.white.primary};
  ${fromMd} {
    stroke: ${o =>
      o.$darkContentDesktop
        ? o.theme.color.deprecated.obsidian
        : o.theme.color.deprecated.white.primary};
  }
  stroke-width: 2px;
`
export const ActiveTabDot = styled.circle<{
  $darkContentMobile: boolean
  $darkContentDesktop: boolean
}>`
  cx: 12px;
  cy: 12px;
  r: 3px;
  fill: ${o =>
    o.$darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    fill: ${o =>
      o.$darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
`

export const InactiveTabDot = styled.circle<{
  $darkContentMobile: boolean
  $darkContentDesktop: boolean
}>`
  cx: 12px;
  cy: 12px;
  r: 3px;
  fill: ${o =>
    o.$darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd}{
    fill: ${o =>
      o.$darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone}};
  }
`

export const TabButton = styled.button`
  width: 24px;
  height: auto;
`

export const Header = styled(TypeStyleHeadlineExtraExtraSmall)<{ $darkContent: boolean }>`
  text-align: center;
  grid-column: 2;
  justify-self: center;
  align-self: center;
  text-transform: uppercase;
  color: ${o =>
    !o.$darkContent
      ? o.theme.color.deprecated.white.primary
      : o.theme.color.deprecated.black.primary};
  margin-top: ${o => o.theme.spacing(7.5)};
  margin-bottom: auto;
  ${o => o.theme.typography.effect.uppercase}
`

export const SlideContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const SubheaderWrapper = styled.div`
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${o => o.theme.spacing(8)};
`
export const Subheader = styled.div<{ $isCurrentSlide: boolean; $darkContent: boolean }>`
  ${o => (o.$isCurrentSlide ? typeStyleMap['headlineMedium'] : typeStyleMap['headlineExtraSmall'])}
  text-align: center;
  color: ${o =>
    o.$isCurrentSlide
      ? !o.$darkContent
        ? o.theme.color.deprecated.white.primary
        : o.theme.color.deprecated.black.primary
      : !o.$darkContent
      ? o.theme.color.deprecated.stone
      : o.theme.color.deprecated.gray.darkest};
  ${o => o.theme.typography.effect.uppercase}
  text-transform: uppercase;
  transition:
    color 0.6s ease,
    font-size 0.6s ease,
    line-height 0.6s ease,
    letter-spacing 0.6s ease;
`

export const SlideCtaButton = styled(Button)`
  margin-top: ${o => o.theme.spacing(7.5)};
`

export const MediaAsContentsInheritPadding = styled(MediaAsContents)`
  padding: inherit;
`
type PageInteractiveSlideSectionType = Extract<
  NonNullable<NonNullable<GetPageInteractiveSlideSectionQuery['pageViewSection']>>,
  { __typename: 'PageInteractiveSlideSection' }
>
export const ContentfulPageInteractiveSlideSectionClient: FC<PageInteractiveSlideSectionType> = ({
  title,
  interactiveSlides,
}) => {
  const sectionContext = useContentfulPageSectionContext()

  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const currentSlide = interactiveSlides[currentSlideIndex]
  const [isPaused, setIsPaused] = useState(false)
  const { pageSectionIndex: pageSectionIndex } = useContentfulPageSectionContext()
  const handleTabClick = (index: number) => {
    if (index === currentSlideIndex) {
      setIsPaused(!isPaused)
    } else {
      setIsPaused(true)
      setCurrentSlideIndex(index)
    }
  }

  const incrementSlide = () => {
    setCurrentSlideIndex(prevIndex => (prevIndex + 1) % interactiveSlides.length)
  }
  const handleContentMouseOver = (index: number) => {
    setCurrentSlideIndex(index)
    setIsPaused(true)
  }
  const handleContentMouseLeave = () => {
    setIsPaused(false)
  }
  const handleSubheaderClickMobile = (index: number) => {
    setCurrentSlideIndex(index)
    setIsPaused(true)
  }

  return (
    <>
      {interactiveSlides.map((slide, index) => (
        <ImageContainer key={index} $visible={currentSlideIndex === index}>
          <MediaAsContentsInheritPadding lessThan='md'>
            <OverlayColor
              $darkContent={currentSlide?.contentColorMobile === 'dark'}
              $customOpacity={currentSlide?.overlayOpacity}
            >
              <StyledImage
                loading='lazy'
                src={'https:' + slide.imageMobile}
                aspectRatios={{
                  sm: slide.aspectRatioMobile ?? 1,
                  md: slide.aspectRatioDesktop ?? 1,
                }}
                widths={{ unit: 'vw', sm: 100, md: 100 }}
                alt={slide.altText ?? ''}
              />
            </OverlayColor>
          </MediaAsContentsInheritPadding>

          <MediaAsContentsInheritPadding greaterThanOrEqual='md'>
            <OverlayColor
              $darkContent={currentSlide?.contentColorDesktop === 'dark'}
              $customOpacity={currentSlide?.overlayOpacity}
            >
              <StyledImage
                loading='lazy'
                aspectRatios={{
                  sm: slide.aspectRatioMobile ?? 1,
                  md: slide.aspectRatioDesktop ?? 1,
                }}
                src={'https:' + slide.imageDesktop}
                widths={{ unit: 'vw', sm: 100, md: 100 }}
                alt={slide.altText ?? ''}
              />
            </OverlayColor>
          </MediaAsContentsInheritPadding>
        </ImageContainer>
      ))}
      <ContentOverlay>
        <TabButtonColumn>
          {interactiveSlides.map((slide, index) => (
            <TabButton
              onFocus={() => setIsPaused(true)}
              onBlur={() => setIsPaused(false)}
              role='tab'
              id={'tab' + index}
              aria-controls={'tabpanel' + index}
              key={index}
              onMouseDown={() => handleTabClick(index)}
            >
              {index === currentSlideIndex ? (
                <TabSVG>
                  {isPaused ? (
                    <PauseSVG>
                      <StyledPath
                        $darkContentMobile={slide.contentColorMobile === 'dark'}
                        $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                        d='M10 7C10.5523 7 11 7.44772 11 8V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V8C9 7.44772 9.44772 7 10 7Z'
                      />
                      <StyledPath
                        $darkContentMobile={slide.contentColorMobile === 'dark'}
                        $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                        d='M14 7C14.5523 7 15 7.44772 15 8V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V8C13 7.44772 13.4477 7 14 7Z'
                      />
                    </PauseSVG>
                  ) : (
                    <ActiveTabDot
                      $darkContentMobile={slide.contentColorMobile === 'dark'}
                      $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                    />
                  )}
                  <CompleteActiveCircle
                    $darkContentMobile={slide.contentColorMobile === 'dark'}
                    $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                  />
                  <AnimatedProgressCircle
                    $darkContentMobile={slide.contentColorMobile === 'dark'}
                    $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                    onAnimationEnd={incrementSlide}
                    $isPaused={isPaused}
                  />
                </TabSVG>
              ) : (
                <TabSVG>
                  <InactiveTabDot
                    $darkContentMobile={slide.contentColorMobile === 'dark'}
                    $darkContentDesktop={slide.contentColorDesktop === 'dark'}
                  />
                </TabSVG>
              )}
            </TabButton>
          ))}
        </TabButtonColumn>
        <MediaAsContents lessThan='md'>
          <Header $darkContent={currentSlide?.contentColorMobile === 'dark'}>{title}</Header>
        </MediaAsContents>
        <MediaAsContents greaterThanOrEqual='md'>
          <Header $darkContent={currentSlide?.contentColorDesktop === 'dark'}>{title}</Header>
        </MediaAsContents>
        <SubheaderWrapper>
          {interactiveSlides.map((slide, index: number) => (
            <SlideContentWrapper key={slide.id}>
              <MediaAsContents greaterThanOrEqual='md'>
                <Subheader
                  onMouseOver={() => handleContentMouseOver(index)}
                  onMouseLeave={() => handleContentMouseLeave()}
                  role='tabpanel'
                  id={'tabpanel' + index}
                  aria-labelledby={'tab' + index}
                  $isCurrentSlide={currentSlideIndex === index}
                  onFocus={() => setIsPaused(true)}
                  onBlur={() => setIsPaused(false)}
                  tabIndex={0}
                  $darkContent={currentSlide?.contentColorDesktop === 'dark'}
                >
                  {slide.title}
                </Subheader>
              </MediaAsContents>
              <MediaAsContents lessThan='md'>
                <Subheader
                  role='tabpanel'
                  id={'tabpanel' + index}
                  aria-labelledby={'tab' + index}
                  $isCurrentSlide={currentSlideIndex === index}
                  onFocus={() => setIsPaused(true)}
                  onBlur={() => setIsPaused(false)}
                  tabIndex={0}
                  $darkContent={currentSlide?.contentColorMobile === 'dark'}
                  onClick={() => handleSubheaderClickMobile(index)}
                >
                  {slide.title}
                </Subheader>
              </MediaAsContents>

              {currentSlideIndex === index && (
                <>
                  <MediaAsContents lessThan='md'>
                    {
                      <SlideCtaButton
                        forwardedAs={NextLink}
                        {...trackEvent({
                          category: sectionContext.pageSectionAnalyticsName,
                          action: 'click',
                          label: 'slide cta button',
                          value: slide.ctaLink,
                          pageSectionName: sectionContext.pageSectionDisplayName,
                          pageSectionIndex,
                          correspondingAsset: currentSlide?.imageMobile ?? undefined,
                        })}
                        href={slide.ctaLink}
                        onFocus={() => setIsPaused(true)}
                        variant={
                          slide.contentColorMobile === 'dark'
                            ? 'white-on-black-no-border'
                            : 'black-on-white-no-border'
                        }
                      >
                        {slide.ctaText}
                      </SlideCtaButton>
                    }
                  </MediaAsContents>
                  <MediaAsContents greaterThanOrEqual='md'>
                    <SlideCtaButton
                      {...trackEvent({
                        category: sectionContext.pageSectionAnalyticsName,
                        action: 'click',
                        label: 'slide cta button',
                        value: slide.ctaLink,
                        pageSectionName: sectionContext.pageSectionDisplayName,
                        pageSectionIndex,
                        correspondingAsset: currentSlide?.imageDesktop ?? undefined,
                      })}
                      forwardedAs={NextLink}
                      href={slide.ctaLink}
                      onFocus={() => setIsPaused(true)}
                      variant={
                        slide.contentColorDesktop === 'dark'
                          ? 'white-on-black-no-border'
                          : 'black-on-white-no-border'
                      }
                    >
                      {slide.ctaText}
                    </SlideCtaButton>
                  </MediaAsContents>
                </>
              )}
            </SlideContentWrapper>
          ))}
        </SubheaderWrapper>
      </ContentOverlay>
    </>
  )
}
