'use client'

import React from 'react'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import {
  TypeStyle,
  TypeStyleHeadlineExtraExtraSmall,
} from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd, untilMd } from '@syconium/little-miss-figgy/dist/constants/breakpoints'

import { Tile as BaseTile } from '../../../tiles/Tiles.client'

type TitleBaseProps = {
  $textAlign?: string
  $mobileTextAlign?: string
  $hasHorizontalMargin?: boolean
}

const TitleBase = css<TitleBaseProps>`
  margin-bottom: 16px;
  padding: 0 ${o => o.$hasHorizontalMargin && o.theme.dimensions.layout.sidePadding.md + 'px'};
  text-align: ${props => props.$mobileTextAlign ?? 'left'};

  ${fromMd} {
    text-align: ${props => props.$textAlign ?? 'left'};
    margin-bottom: 24px;
  }
`
export const TitleDefault = styled(TypeStyle.HeadlineSmall)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleMedium = styled(TypeStyle.HeadlineMedium)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleLarge = styled(TypeStyle.HeadlineLarge)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleExtraLarge = styled(TypeStyle.HeadlineExtraLarge)<TitleBaseProps>`
  ${TitleBase}
`
export const PageTileSectionTitle: React.FC<
  {
    $titleTextSize: 'h1' | 'h2' | 'h3' | 'h4'
    children: string | ReactNode
  } & TitleBaseProps
> = ({ $titleTextSize, ...props }) => {
  switch ($titleTextSize) {
    case 'h1':
      return (
        <TitleExtraLarge asTag={$titleTextSize} {...props}>
          {props.children}
        </TitleExtraLarge>
      )
    case 'h2':
      return (
        <TitleLarge asTag={$titleTextSize} {...props}>
          {props.children}
        </TitleLarge>
      )
    case 'h3':
      return (
        <TitleMedium asTag={$titleTextSize} {...props}>
          {props.children}
        </TitleMedium>
      )
    default:
      return (
        <TitleDefault asTag={$titleTextSize} {...props}>
          {props.children}
        </TitleDefault>
      )
  }
}

export const Container = styled.div<{
  $textAlign: 'center' | 'left' | 'right'
  $hasHover: boolean
  $hasVerticalMargin?: boolean
}>`
  position: relative;
  text-align: ${o => o.$textAlign};
  padding: ${o => (o.$hasVerticalMargin ? '2px 0' : '0')};

  ${o =>
    o.$hasHover &&
    `
      &:hover ${Image},
      {
        display: none;
      }
      `}
`

export const ImageWrap = styled.figure<{
  $aspectRatios: { sm: number; md: number }
  $roundCorners: { sm: boolean; md: boolean }
}>`
  display: block;
  overflow: hidden;
  padding-top: ${o => (1 / o.$aspectRatios.sm) * 100}%;
  position: relative;
  width: 100%;

  ${untilMd} {
    border-radius: ${o => o.$roundCorners.sm && '6px'};
  }

  & > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
  ${fromMd} {
    padding-top: ${o => (1 / o.$aspectRatios.md) * 100}%;
    border-radius: ${o => o.$roundCorners.md && '6px'};
  }
`

export const TextOverlay = styled.div<{
  $textPlacement: 'center' | 'below' | 'bottom'
}>`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${o => (o.$textPlacement === 'center' ? 'center' : 'space-between')};
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`

export const TextOverlayMiddle = styled.div`
  box-sizing: border-box;
  padding: 0 6%;
  width: 100%;
`

export const TextOverlayBottom = styled.div<{
  $textPlacement: 'center' | 'bottom'
}>`
  background-color: ${o =>
    o.$textPlacement === 'bottom' && o.theme.color.textOverlay.transparent.fill};
  color: ${o =>
    o.$textPlacement === 'bottom' && o.theme.color.textOverlay.transparent.content.on.fill};
  box-sizing: border-box;
  height: ${o => (o.$textPlacement === 'bottom' ? 'auto' : '0')};
  overflow: hidden;
  padding: 0 6%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  div {
    display: inline-block;
  }
`
export const TextBelow = styled.div`
  margin: 12px auto 0;
  max-width: 88vw;
  width: 100%;
  div {
    display: inline-block !important;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const LinkTypeStyle = styled(TypeStyleHeadlineExtraExtraSmall)`
  margin: 12px auto;
  text-transform: uppercase;
  ${o => o.theme.typography.effect.uppercase}
`

export const Tile = styled(BaseTile)`
  position: relative;
`
