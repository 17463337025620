'use client'

import React, { ComponentProps } from 'react'
import styled, { keyframes } from 'styled-components'

import { MediaAsContents } from '@syconium/little-miss-figgy'
import { Button as LMFButton } from '@syconium/little-miss-figgy/dist/components/Button/Button'
import { ScrollDownArrow } from '@syconium/little-miss-figgy/dist/components/Icons'
import { ResponsiveImage } from '@syconium/little-miss-figgy/dist/components/Image/ResponsiveImage'
import { ImageShrink } from '@syconium/little-miss-figgy/dist/components/ImageShrink'
import { TypeStyle } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import {
  easeInOutCubic,
  fromMd,
  fromXl,
  untilMd,
} from '@syconium/little-miss-figgy/dist/themes/default/breakpoints'

import { PageHeroSection } from '../../../../../__generated__/graphql/catalog/graphql'
import { ButtonBar } from '../../../../../brunswick/components/atoms/ButtonBar'
import {
  sectionGapDesktop,
  sectionGapMobile,
} from '../../../../../brunswick/components/page-sections/styles'

// Mobile, textPlacementMobile='below', image is 320x270
const arrowHeight = 15
const arrowPadMobile = 42
const arrowPadDesktop = 56

export const Container = styled.section<{
  $isFullscreenHeight: boolean
  $textPlacementMobile: PageHeroSection['textPlacementMobile']
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${o => (o.$textPlacementMobile === 'bottom' ? 'flex-end' : 'center')};
  position: relative;
  box-sizing: border-box;

  min-height: 100vw;
  ${o => (o.$textPlacementMobile !== 'below' ? 'min-height: min(77vh,136vw)' : null)};
  ${o => (o.$isFullscreenHeight ? 'min-height: 100svh' : null)};

  ${fromMd} {
    justify-content: center;
    min-height: ${o => (o.$isFullscreenHeight ? '100svh' : '50vw')};
  }

  ${fromXl} {
    min-height: ${o => (o.$isFullscreenHeight ? '100svh' : '800px')};
  }
`

export const ImageWrap = styled.div<{
  $backgroundColor: string | null
  $shouldCollapse: boolean
  $textPlacementMobile: PageHeroSection['textPlacementMobile']
}>`
  background: ${o => o.$backgroundColor};
  height: 100%;
  position: absolute;
  width: 100%;

  ${o =>
    o.$textPlacementMobile === 'below' &&
    `
    ${fromMd} {
      padding-top: 0;
      position: absolute;
    }
    `}

  img,
  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;

    ${fromMd} {
      object-fit: cover;
      height: 100%;
    }
  }
`

export const StyledImageWrapOverlay = styled.div<{
  $overlayColor: string | null
}>`
  background: ${o => o.$overlayColor};
  inset: 0px;
  position: absolute;
  pointer-events: none;
`

export const Eyebrow = styled(TypeStyle.Eyebrow)`
  margin: 0 auto 8px;
  max-width: 352px;
  text-transform: uppercase;

  ${fromMd} {
    margin-bottom: 16px;
    max-width: min(70vw, 100%);
  }
`

export const Title = styled(TypeStyle.HeadlineMedium)<{
  $isTitleSerif: boolean | null
  $isTitleItalic: boolean | null
}>`
  font-family: ${({ $isTitleSerif, theme: { typography } }) =>
    $isTitleSerif
      ? typography.deprecated.font.family.editorial
      : typography.deprecated.font.family.primary};
  font-style: ${o => o.$isTitleItalic && 'italic'};
  margin: 0 auto;
  max-width: 532px;

  ${fromMd} {
    max-width: min(80vw, 100%);
  }
`

export const Subtitle = styled(TypeStyle.BodyLarge)`
  margin: 4px auto 0;
  max-width: min(85vw, 100%);

  ${fromMd} {
    max-width: min(70vw, 100%);
    margin-top: 20px;
  }
`

export const VideoModalButtonWrapper = styled.div`
  margin-top: ${o => o.theme.spacing(2)};
`

interface StyledTextWrapProps {
  $mobileTextColor: string
  $desktopTextColor: string
  $textContentMaxWidthDesktop: number | null
  $textContentMaxWidthMobile: number | null
  $textPlacementDesktop: PageHeroSection['textPlacementDesktop']
  $textPlacementMobile: PageHeroSection['textPlacementMobile']
}

export const StyledTextWrap = styled.div<StyledTextWrapProps>`
  align-items: center;
  box-sizing: border-box;
  color: ${o => o.$mobileTextColor};
  display: flex;
  flex-direction: column;
  padding: ${o => (o.$textPlacementMobile === 'below' ? '16px' : '73px')}
    ${o => o.theme.dimensions.layout.sidePadding.md}px;
  position: relative;
  text-align: center;
  width: 100%;

  ${untilMd} {
    ${Eyebrow},
    ${Title},
    ${Subtitle} {
      max-width: ${o => o.$textContentMaxWidthMobile && `${o.$textContentMaxWidthMobile}px`};
    }

    > picture {
      width: 100%;
    }
  }

  ${fromMd} {
    color: ${o => o.$desktopTextColor};
    align-self: ${o =>
      o.$textPlacementDesktop === 'left'
        ? 'flex-start'
        : o.$textPlacementDesktop === 'right'
        ? 'flex-end'
        : 'center'};
    padding: 24px 40px;
    width: ${o => (o.$textPlacementDesktop === 'center' ? '100%' : '50%')};

    ${Eyebrow},
    ${Title},
    ${Subtitle} {
      max-width: ${o => o.$textContentMaxWidthDesktop && `${o.$textContentMaxWidthDesktop}px`};
    }
  }

  picture {
    display: contents;
  }
`
export const TextWrap: React.FC<StyledTextWrapProps & { children: React.ReactNode }> = ({
  children,
  ...props
}) => {
  return <StyledTextWrap {...props}>{children}</StyledTextWrap>
}

export const ImageWrapOverlay: React.FC<{ overlayColor: string | null }> = ({ overlayColor }) => {
  return <StyledImageWrapOverlay $overlayColor={overlayColor} />
}

export const MobileBelowTitleImage = styled(ImageShrink)<{
  $titleImageMaxWidthMobile: number | null
}>`
  display: block;
  margin: 0 auto;
  max-width: ${o => o.$titleImageMaxWidthMobile && `${o.$titleImageMaxWidthMobile}px`};
  width: 100%;
`

export const TextWrapTitleImage = styled(ResponsiveImage)<{
  $titleImageMaxWidthDesktop: number | null
  $titleImageMaxWidthMobile: number | null
}>`
  display: block;
  margin: 0 auto 16px;

  ${untilMd} {
    max-width: ${o => o.$titleImageMaxWidthMobile && `${o.$titleImageMaxWidthMobile}px`};
    width: 100%;
  }

  ${fromMd} {
    display: block;
    margin: 0 auto 24px;
    max-width: ${o => o.$titleImageMaxWidthDesktop && `${o.$titleImageMaxWidthDesktop}px`};
    width: 80%;
  }

  ${Eyebrow} + & {
    margin-top: 8px;

    ${fromMd} {
      margin-top: 16px;
    }
  }
`

export const TopArrowSpacer = styled.div`
  display: none;
  height: ${arrowHeight + arrowPadMobile}px;

  ${fromMd} {
    display: initial;
    height: ${arrowHeight + arrowPadDesktop}px;
  }
`

const arrowDip = keyframes`
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(16px);
  }
  100% {
    transform: translateY(0);
  }
`

export const StyledScrollDownArrow = styled(ScrollDownArrow)<{
  $textColor: string
  $hideDownArrow: boolean | null
  $textPlacementMobile?: PageHeroSection['textPlacementMobile']
}>`
  display: ${o => o.$textPlacementMobile === 'below' && 'none'};
  animation: ${arrowDip} 4s infinite ${easeInOutCubic};
  bottom: 0;
  color: ${o => o.$textColor};
  padding-bottom: ${arrowPadMobile}px;
  position: absolute;
  visibility: ${o => (o.$hideDownArrow ? 'hidden' : 'visible')};

  ${fromMd} {
    display: initial;
    padding-bottom: ${arrowPadDesktop}px;
  }
`

export const StyledButtonList = styled.ul`
  display: flex;
`

export const StyledButtonListItem = styled.li`
  min-width: 155px;
`

export const StyledButtonBar = styled(ButtonBar)`
  margin-top: 16px;

  ${fromMd} {
    margin-top: 24px;
  }
`

export const TextBelowSection = styled.section`
  position: relative;
`

export const HeroBottomMargin = styled.div`
  margin-bottom: ${sectionGapMobile}px;

  ${fromMd} {
    margin-bottom: ${sectionGapDesktop / 2}px;
  }
`

export const HeroImage: React.FC<{
  imageDesktop: string | null
  imageMobile: string | null
  alt: string | null
  isCriticalImage: boolean
}> = ({ imageMobile, imageDesktop, alt, isCriticalImage }) => {
  const imageSrc = imageDesktop ?? imageMobile

  if (!imageSrc) return null

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  return (
    <ResponsiveImage
      src={imageSrc}
      alt={alt ?? ''}
      aspectRatios={{
        sm: null,
        md: null,
      }}
      loading={isCriticalImage ? 'default' : 'lazy'}
      fetchPriority={isCriticalImage ? 'high' : undefined}
      widths={{ unit: 'vw', sm: 100, md: 100 }}
      srcs={srcs}
    />
  )
}

export const CtaButton = ({
  variantDesktop,
  variantMobile,
  ...rest
}: ComponentProps<typeof LMFButton> & {
  variantDesktop: ComponentProps<typeof LMFButton>['variant']
  variantMobile: ComponentProps<typeof LMFButton>['variant']
}) => {
  return (
    <>
      <MediaAsContents lessThan='md'>
        <LMFButton {...rest} variant={variantMobile} />
      </MediaAsContents>
      <MediaAsContents greaterThanOrEqual='md'>
        <LMFButton {...rest} variant={variantDesktop} />
      </MediaAsContents>
    </>
  )
}
