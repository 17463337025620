'use client'

import { Tab, TabList, TabProvider, useStoreState, useTabContext } from '@ariakit/react'
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled, { keyframes, useTheme } from 'styled-components'

import {
  AnimatedTabPanel,
  Carat,
  IconButton,
  PauseButton,
  RotateClockwise,
  fromLg,
  fromMd,
  useSupportsHover,
  useSwipe,
  userPrefersReducedMotion,
  withHoverSupport,
} from '@syconium/little-miss-figgy'

import { trackEvent } from '../../../lib/analytics'
import { isActionableElement } from '../../../lib/utils/isActionableElement'
import { useTranslation } from '../../_providers/TranslationProvider.client'
import { useHeap } from '../chrome/scripts/HeapScript'
import { useContentfulPageSectionContext } from '../pages/contentful/ContentfulPageSectionProvider.client'

type CarouselContextValue = {
  status: 'play' | 'pause'
  selectedStatus: 'play' | 'pause'
  toggleSelectedStatus: () => void
  goToNextSlide: () => void
  goToPreviousSlide: () => void
  goToSlide: (slideId: string) => void
  currentSlideIndex: number
  currentSlideId: string | undefined
}

const CarouselContext = createContext<CarouselContextValue | undefined>(undefined)

export const useCarouselContext = () => {
  return useContext(CarouselContext)
}

type CarouselProps = {
  defaultSelectedSlideId: string
} & CarouselImplProps

export const Carousel = ({ defaultSelectedSlideId, ...props }: CarouselProps) => {
  return (
    <TabProvider defaultSelectedId={defaultSelectedSlideId}>
      <CarouselImpl {...props} />
    </TabProvider>
  )
}

type CarouselImplProps = {
  className?: string
  children?: ReactNode
}

const CarouselImpl = styled(({ className, children }: CarouselImplProps) => {
  const sectionElementRef = useRef<HTMLElement | null>(null)

  const [carouselHovered, setCarouselHovered] = useState(false)
  const [actionVisiblyFocused, setActionVisiblyFocused] = useState(false)

  const [manualPlayStatus, setManualPlayStatus] = useState<'play' | 'pause' | null>(null)
  const [autoPlayAllowed, setAutoPlayAllowed] = useState(false)

  const tabStore = useTabContext()
  const tabStoreState = useStoreState(tabStore)

  const [previousSlideIndex, currentSlideIndex, nextSlideIndex] = useMemo(() => {
    if (!tabStoreState?.items) return [0, 0, 0]
    const index = tabStoreState.items.findIndex(item => item.id === tabStoreState?.selectedId) ?? 0
    const nextIndex = index + 1 < tabStoreState.items.length ? index + 1 : 0
    const previousIndex = index - 1 >= 0 ? index - 1 : tabStoreState.items.length - 1
    return [previousIndex, index, nextIndex]
  }, [tabStoreState?.items, tabStoreState?.selectedId])

  const [previousSlide, currentSlide, nextSlide] = useMemo(() => {
    return [
      tabStoreState?.items[previousSlideIndex],
      tabStoreState?.items[currentSlideIndex],
      tabStoreState?.items[nextSlideIndex],
    ]
  }, [currentSlideIndex, nextSlideIndex, previousSlideIndex, tabStoreState?.items])

  useEffect(() => {
    setAutoPlayAllowed(userPrefersReducedMotion === false)
  }, [])

  useEffect(() => {
    const sectionElement = sectionElementRef.current
    if (sectionElement) {
      const focusHandler = (event: FocusEvent) => {
        if (isActionableElement(event.target) && event.target.matches('*:focus-visible')) {
          setActionVisiblyFocused(true)
        } else {
          setActionVisiblyFocused(false)
        }
      }
      const blurHandler = () => {
        setActionVisiblyFocused(false)
      }
      const mouseEnterHandler = () => {
        setCarouselHovered(true)
      }
      const mouseLeaveHandler = () => {
        setCarouselHovered(false)
      }

      sectionElement.addEventListener('mouseenter', mouseEnterHandler)
      sectionElement.addEventListener('mouseleave', mouseLeaveHandler)
      sectionElement.addEventListener('focusin', focusHandler)
      sectionElement.addEventListener('focusout', blurHandler)
      return () => {
        sectionElement.removeEventListener('mouseenter', mouseEnterHandler)
        sectionElement.removeEventListener('mouseleave', mouseLeaveHandler)
        sectionElement.removeEventListener('focusin', focusHandler)
        sectionElement.removeEventListener('focusout', blurHandler)
      }
    }
    return undefined
  }, [])

  const carouselContext = useMemo<CarouselContextValue | undefined>(() => {
    const goToSlide = (slideId: string) => {
      tabStore?.setSelectedId(slideId)
      if (!userPrefersReducedMotion) {
        setManualPlayStatus('play')
      }
    }

    const goToNextSlide = () => {
      tabStore?.setSelectedId(nextSlide?.id)
      if (!userPrefersReducedMotion) {
        setManualPlayStatus('play')
      }
    }

    const goToPreviousSlide = () => {
      tabStore?.setSelectedId(previousSlide?.id)
      if (!userPrefersReducedMotion) {
        setManualPlayStatus('play')
      }
    }

    const selectedStatus = manualPlayStatus ? manualPlayStatus : autoPlayAllowed ? 'play' : 'pause'
    const status = actionVisiblyFocused || carouselHovered ? 'pause' : selectedStatus

    const toggleSelectedStatus = () => {
      setManualPlayStatus(selectedStatus === 'play' ? 'pause' : 'play')
    }

    return {
      status,
      selectedStatus,
      toggleSelectedStatus,
      currentSlideIndex: currentSlideIndex,
      currentSlideId: currentSlide?.id,
      goToSlide,
      goToNextSlide,
      goToPreviousSlide,
    }
  }, [
    manualPlayStatus,
    autoPlayAllowed,
    actionVisiblyFocused,
    carouselHovered,
    currentSlideIndex,
    currentSlide?.id,
    tabStore,
    nextSlide?.id,
    previousSlide?.id,
  ])

  return (
    <CarouselContext.Provider value={carouselContext}>
      <section ref={sectionElementRef} className={className} aria-roledescription={'carousel'}>
        {children}
      </section>
    </CarouselContext.Provider>
  )
})`
  position: relative;
`

type CarouselSlideProps = {
  children?: ReactNode
  className?: string
  id: string
  index: number
}

export const CarouselSlide = styled(({ className, children, id, index }: CarouselSlideProps) => {
  const carouselContext = useContext(CarouselContext)
  const isCarousel = carouselContext !== undefined
  const { sendHeapEvent } = useHeap()

  const onSwipedLeft = useCallback(() => {
    sendHeapEvent({
      eventName: 'pscs-swipe',
      props: {
        'direction': 'left',
        'from-slide': String(carouselContext?.currentSlideIndex),
      },
    })
    carouselContext?.goToNextSlide()
  }, [carouselContext, sendHeapEvent])

  const onSwipedRight = useCallback(() => {
    sendHeapEvent({
      eventName: 'pscs-swipe',
      props: {
        'direction': 'right',
        'from-slide': String(carouselContext?.currentSlideIndex),
      },
    })
    carouselContext?.goToPreviousSlide()
  }, [carouselContext, sendHeapEvent])

  const swipeHandlers = useSwipe({
    onSwipedLeft,
    onSwipedRight,
  })

  if (!isCarousel) return children

  return (
    <AnimatedTabPanel
      className={className}
      tabId={id}
      data-figs-tab-id={id}
      role='group'
      aria-roledescription='slide'
      aria-label={`Slide ${index + 1}`}
      onTouchStart={swipeHandlers.onTouchStart}
      onTouchEnd={swipeHandlers.onTouchEnd}
      onTouchMove={swipeHandlers.onTouchMove}
    >
      {children}
    </AnimatedTabPanel>
  )
})`
  position: absolute;
  inset: 0;
  transition: translate 300ms cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  translate: -100%;

  /* Panel that is open */
  &[data-open] {
    position: static;
    translate: 0;
    z-index: 2;
  }

  :is([data-open]) ~ & {
    translate: 100%;
  }

  @media (prefers-reduced-motion) {
    /* Disable transitions for reduced motion users */
    transition: none;
    translate: 0;
  }

  > *:first-child {
    padding: 7px 0px;
    ${withHoverSupport} {
      padding: 7px 52px;
    }

    ${fromMd} {
      padding: 56px 0px;
      ${withHoverSupport} {
        padding: 56px 60px;
      }
    }

    ${fromLg} {
      padding: 68px 0px;
      ${withHoverSupport} {
        padding: 68px 60px;
      }
    }
  }
`

export const CarouselControls = ({ slideIds }: { slideIds: string[] }) => {
  const {
    magnolia: { general },
    testimonials: { nextSlideButtonLabel, prevSlideButtonLabel },
  } = useTranslation()

  const [hydrated, setHydrated] = useState(false)
  useEffect(() => {
    setHydrated(true)
  }, [])

  const theme = useTheme()
  const deviceSupportsHover = useSupportsHover()
  const carouselContext = useContext(CarouselContext)
  const { pageSectionIndex, pageSectionAnalyticsName } = useContentfulPageSectionContext()

  if (!carouselContext || !hydrated) return null

  const {
    currentSlideIndex,
    currentSlideId,
    goToNextSlide,
    goToPreviousSlide,
    status,
    selectedStatus,
    toggleSelectedStatus,
    goToSlide,
  } = carouselContext

  return (
    <>
      <ControlPlacementBottomCenter>
        <TabList>
          {slideIds.map((slideId, index) => {
            return (
              <Tab
                key={slideId}
                id={slideId}
                onClick={() => {
                  goToSlide(slideId)
                }}
                aria-label={`Slide ${index + 1}`}
                {...trackEvent({
                  action: `switch to tab ${index}`,
                  category: pageSectionAnalyticsName,
                  pageSectionIndex,
                  label: 'slide ' + String(currentSlideIndex),
                })}
              >
                {currentSlideId === slideId ? (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle
                      cx='12'
                      cy='12'
                      r='6'
                      fill={theme.color.button.primary.content.on.background}
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <circle
                      cx='12'
                      cy='12'
                      r='4'
                      fill={theme.color.stroke.neutral.border.interaction.secondary.on.background}
                    />
                  </svg>
                )}
              </Tab>
            )
          })}
        </TabList>
      </ControlPlacementBottomCenter>
      <ControlPlacementBottomRight>
        <SlideProgressSvgElement key={currentSlideIndex}>
          <SlideProgressCircleElement
            onAnimationIteration={goToNextSlide}
            $isPaused={status === 'pause'}
          />
        </SlideProgressSvgElement>
        <PauseButton
          focusOutlineOffset={8} // Larger offset to get outside of the SlideProgressSVG
          size={'small'}
          paused={selectedStatus === 'pause'}
          onClick={toggleSelectedStatus}
          pauseText={general.pauseVideo}
          playText={general.playVideo}
          {...trackEvent({
            action: `click pause button`,
            category: pageSectionAnalyticsName,
            value: `${selectedStatus === 'pause' ? 'play button' : 'pause button'}`,
            label: 'slide ' + String(currentSlideIndex),
            pageSectionIndex,
          })}
        />
      </ControlPlacementBottomRight>
      {deviceSupportsHover && (
        <>
          <ControlPlacementLeft>
            <IconButton
              variant='black-on-white-no-border'
              onClick={() => {
                goToPreviousSlide()
              }}
              aria-label={prevSlideButtonLabel}
              {...trackEvent({
                action: `click to previous page section panel`,
                category: pageSectionAnalyticsName,
                label: 'slide ' + String(currentSlideIndex),
                pageSectionIndex,
              })}
            >
              <Carat rotateClockwise={RotateClockwise.Half} height='18' width='16' />
            </IconButton>
          </ControlPlacementLeft>
          <ControlPlacementRight>
            <IconButton
              variant='black-on-white-no-border'
              onClick={() => {
                goToNextSlide()
              }}
              aria-label={nextSlideButtonLabel}
              {...trackEvent({
                action: `click to next page section panel`,
                category: pageSectionAnalyticsName,
                label: 'slide ' + String(currentSlideIndex),
                pageSectionIndex,
              })}
            >
              <Carat height='18' width='16' />
            </IconButton>
          </ControlPlacementRight>
        </>
      )}
    </>
  )
}

const SlideProgressAnimationKeyframes = keyframes`
  from {
    stroke-dashoffset: 126px;
  }

  to {
    stroke-dashoffset: 0px;
  }
`

const SlideProgressCircleElement = styled.circle<{
  $isPaused: boolean
}>`
  stroke-dasharray: 126px;
  cx: 21px;
  cy: 21px;
  r: 20px;
  fill: transparent;
  stroke: ${o => o.theme.color.fill.background};
  stroke-width: 2px;

  animation: ${SlideProgressAnimationKeyframes} 5s linear infinite;
  animation-play-state: ${o => (o.$isPaused ? 'paused' : 'running')};
  @media (prefers-reduced-motion) {
    display: none;
    animation: none;
  }
`

const SlideProgressSvgElement = styled.svg`
  height: 42px;
  width: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  pointer-events: none;

  &: (prefers-reduced-motion: reduce) {
    display: none;
  }
`

const ControlPlacementBottomCenter = styled.div`
  position: absolute;
  bottom: ${o => o.theme.spacing(4)};
  ${fromMd} {
    bottom: ${o => o.theme.spacing(8)};
  }
  ${fromLg} {
    bottom: ${o => o.theme.spacing(12)};
  }
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  -webkit-tap-highlight-color: transparent;
`

const ControlPlacementBottomRight = styled.div`
  position: absolute;
  right: 20px;
  bottom: 21px;
  ${fromMd} {
    right: 45px;
    bottom: 45px;
  }
  z-index: 1;
  height: 32px;
`

const ControlPlacementRight = styled.div`
  position: absolute;
  right: 20px;
  ${fromMd} {
    right: 40px;
  }
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 1;
`

const ControlPlacementLeft = styled.div`
  position: absolute;
  left: 20px;
  ${fromMd} {
    left: 40px;
  }
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  opacity: 1;
`
