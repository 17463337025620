'use client'

import { ComponentProps, useEffect, useState } from 'react'

import VimeoVideo from '../../../brunswick/components/VimeoVideo/VimeoVideo'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'
import { InlineVideoPlatforms } from '../../../types/video'
import { useCarouselContext } from '../carousels/Carousels.client'

import { MaxIterationsVideo } from './MaxIterationsVideo.client'

type ClientOnlyResponsiveVideoSrcProps = Parameters<typeof useResponsiveVideoSrc>[0] & {
  playText: string
  pauseText: string
  objectPosition?: ComponentProps<typeof VimeoVideo>['objectPosition']
  pauseButtonPosition?: ComponentProps<typeof VimeoVideo>['pauseButtonPosition']
}

export const ClientOnlyResponsiveVideo = ({
  playText,
  pauseText,
  objectPosition = 'center',
  pauseButtonPosition,
  ...responsiveVideoSrcProps
}: ClientOnlyResponsiveVideoSrcProps) => {
  const video = useResponsiveVideoSrc(responsiveVideoSrcProps)
  const [isClient, setIsClient] = useState(false)

  const carouselContext = useCarouselContext()

  useEffect(() => {
    setIsClient(true)
  }, [])

  if (!isClient) return null
  if (!video) return null

  if (video.platform === InlineVideoPlatforms.VIMEO) {
    return (
      <VimeoVideo
        playText={playText}
        pauseText={pauseText}
        autoplay
        includePauseButton
        lazy
        videoSrc={video.src}
        objectPosition={objectPosition}
        pauseButtonPosition={pauseButtonPosition}
        overridePlayState={carouselContext?.status}
      />
    )
  }

  return (
    <MaxIterationsVideo
      autoPlay
      isLazy
      loop
      maxIterations={5}
      muted
      playsInline
      src={video.src}
      aspectRatios={null}
    />
  )
}
