'use client'

import { DefaultTheme, ThemeProvider } from 'styled-components'

import {
  defaultDarkTheme,
  defaultLightTheme,
} from '@syconium/little-miss-figgy/dist/themes/default/default'
import {
  olympicDarkTheme,
  olympicLightTheme,
} from '@syconium/little-miss-figgy/dist/themes/olympic/olympic'

import { GetContentfulPageThemeQuery } from '../../../../__generated__/graphql/catalog/graphql'

type ContentfulPageThemeProps = {
  themeOverride: NonNullable<NonNullable<GetContentfulPageThemeQuery['pageView']>['themeOverride']>
  children: React.ReactNode
}

const themeForOverrideKey: Record<ContentfulPageThemeProps['themeOverride'], DefaultTheme> = {
  dark: defaultDarkTheme,
  light: defaultLightTheme,
  olympicDark: olympicDarkTheme,
  olympicLight: olympicLightTheme,
} as const

export const ContentfulPageTheme = ({ themeOverride, children }: ContentfulPageThemeProps) => {
  return <ThemeProvider theme={themeForOverrideKey[themeOverride]}>{children}</ThemeProvider>
}
