import styled from 'styled-components'

import { PauseButton } from '@syconium/little-miss-figgy'

export const VimeoWrapper = styled.div`
  position: absolute;
  inset: 0;
  overflow: hidden;
  isolation: isolate;
  will-change: transform;
`

export interface VimeoIframeProps {
  $isInteractive: boolean
  $aspectRatio: number
  $isVisible: boolean
  $objectPosition: 'center' | 'top'
}

export const VimeoIframe = styled.iframe<VimeoIframeProps>`
  box-sizing: border-box;
  aspect-ratio: ${o => o.$aspectRatio};
  ${o => (o.$isVisible ? null : 'display: none;')};

  position: absolute;
  left: 50%;
  top: ${o => (o.$objectPosition === 'center' ? '50%' : '0%')};
  transform: translate(-50%, ${o => (o.$objectPosition === 'center' ? '-50%' : '0%')});

  min-height: 100%;
  min-width: 100%;

  z-index: ${o => (o.$isInteractive === false ? -1 : 'auto')};
`

export const NonInteractiveThumbnailContainer = styled.div<{ position?: string }>`
  height: 100%;
  left: 0;
  position: ${o => o.position ?? 'absolute'};
  top: 0;
  width: 100%;
  z-index: -1;
  line-height: 0px;
`

export const StyledPauseButton = styled(PauseButton)`
  z-index: 200;
`
