'use client'

import { useFeatureFlag } from '../../../../_providers/ExperimentationProvider.client'

// Server side A/B test decisions are not available to Heap. This A/B test is being actively measured in Heap. So just call this to send Heap data.
export const TrackPageSectionCarouselAbTest = () => {
  useFeatureFlag({
    key: 'page-section-carousel',
    defaultVariant: 'no-carousel',
    ifAccessedPriorToDecisionInitialization: 'return-null-while-pending',
  })
  return null
}
