'use client'

import styled from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'

import { NextLink } from '../../../navigation/NextLink'
import { PageSection } from '../../PageSection.client'

const desktopAspectRatio = 1.784
const mobileAspectRatio = 2.132

export const Container = styled(PageSection)<{
  $backgroundColor: string
  $desktopTextAlign: 'left' | 'center'
  $textColor: string
}>`
  background: ${o => o.$backgroundColor};
  color: ${o => o.$textColor};
  text-align: center;

  ${fromMd} {
    display: flex;
    text-align: ${o => o.$desktopTextAlign};
  }
`

export const TextWrap = styled.div<{
  $desktopWidth: '50%' | '100%'
}>`
  padding: 20px ${o => o.theme.dimensions.layout.sidePadding.md}px;

  ${fromMd} {
    align-self: center;
    box-sizing: border-box;
    padding: ${o => o.theme.spacing(10)} calc(100% / 12) ${o => o.theme.spacing(5)} calc(100% / 12);
    width: ${o => o.$desktopWidth};
  }
`

export const SubtitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Subtitle = styled.p`
  ${TypeStyle.css.bodyDefault};
  margin-top: 8px;

  ${fromMd} {
    font-size: 16px;
    margin-top: ${o => o.theme.spacing(5)};
    width: 849px;
  }
`

export const Cta = styled(NextLink)`
  margin-top: 16px;
`

export const ImageWrap = styled.div<{
  $imagePlacement: string | null
}>`
  height: 0;
  order: ${o => (o.$imagePlacement === 'right' ? '2' : '-1')};
  overflow: hidden;
  padding-top: calc(1 / ${mobileAspectRatio} * 100%);
  position: relative;

  ${fromMd} {
    padding-top: calc(1 / ${desktopAspectRatio} * 50%);
    width: 50%;
  }

  & > img,
  & > picture > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const VideoDialogWrapper = styled.div`
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
`
