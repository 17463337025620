'use client'

import { ComponentProps, useState } from 'react'
import styled, { ThemeProvider, css, useTheme } from 'styled-components'

import {
  Button,
  ResponsiveImage,
  Spacer,
  TypeStyle,
  focusOutline,
  fromMd,
  timingSlower,
  untilMd,
  useDarkTheme,
  withHoverSupport,
} from '@syconium/little-miss-figgy'

import { trackEvent } from '../../../../../lib/analytics'
import {
  sectionRowGapFromMd,
  sectionRowGapUntilMd,
  sectionSideGutterFromMd,
  sectionSideGutterUntilMd,
} from '../../PageSection.client'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'

import { Tile as BaseTile } from './../../../tiles/Tiles.client'

export const AdaptiveColorThemeProvider = ({
  hasBackground,
  children,
}: {
  hasBackground: boolean
  children?: React.ReactNode
}) => {
  // Contentful doesn't ask Merch if their color or image needs dark mode text colors... so lets just assume for now it does.
  const currentTheme = useTheme()
  const darkTheme = useDarkTheme()
  const theme = hasBackground ? darkTheme : currentTheme
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const BackgroundColor = styled.div<{ $backgroundColor: string }>`
  position: absolute;
  inset: 0;
  background-color: ${o => o.$backgroundColor};
`

export const BackgroundImage = styled(ResponsiveImage)`
  position: absolute;
  inset: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
`

export const ToggleHeadlineIcon = styled.div`
  position: relative;
  min-width: 24px;
  height: 24px;
  background-color: ${o => o.theme.color.text.highContrast.on.dark};
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 1px;
    background-color: ${o => o.theme.color.text.highContrast.on.light};
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(90deg);
    transition: background-color ${timingSlower} ease-in-out;
  }

  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.1);
`

export const ContentWrapper = styled.div<{ $hasBackground: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 40px ${sectionSideGutterUntilMd}px 0px ${sectionSideGutterUntilMd}px;
  ${fromMd} {
    padding: 96px ${sectionSideGutterFromMd}px 0 ${sectionSideGutterFromMd}px;
  }

  ${o =>
    o.$hasBackground
      ? `
      padding-top: ${sectionRowGapUntilMd}px;
      padding-bottom: ${sectionRowGapUntilMd}px;
    `
      : null}

  ${fromMd} {
    ${o =>
      o.$hasBackground
        ? `
      padding-top: ${sectionRowGapFromMd}px;
      padding-bottom: ${sectionRowGapFromMd}px;
    `
        : null}
  }
`

export const Content = styled.div`
  position: relative;
  z-index: 1;
`

export const CarouselWrapper = styled.div`
  margin: 0px -${sectionSideGutterUntilMd}px;
  width: calc(100% + ${sectionSideGutterUntilMd}px + ${sectionSideGutterUntilMd}px);
  ${fromMd} {
    margin: 0px -${sectionSideGutterFromMd}px;
    width: calc(100% + ${sectionSideGutterFromMd}px + ${sectionSideGutterFromMd}px);
  }

  padding-top: 40px;
  ${fromMd} {
    padding-top: 80px;
  }
`

export const EditorialWrapper = styled.div`
  color: ${o => o.theme.color.text.primary.on.background};
  display: grid;
  flex-shrink: 0;
  grid-template-columns: 1fr;

  ${fromMd} {
    gap: 40px;
    grid-template-columns: 1fr 1fr;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: ${o => o.theme.spacing(10)};
  height: fit-content;
  ${untilMd} {
    margin-top: ${o => o.theme.spacing(5)};
  }
  ${fromMd} {
    flex-direction: row;
    grid-row: 2;
    gap: ${o => o.theme.spacing(5)};
  }
  > * {
    flex-shrink: 0;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  grid-row: 2;
  gap: ${o => o.theme.spacing(5)};

  height: fit-content;
`

export const CtaButton = styled(Button)`
  height: fit-content;
  width: fit-content;
  text-align: center;
`

type TileInformationHeadlineProps = ComponentProps<'button'> & {
  expanded: boolean
  onClick: () => void
}

export const TileInformationHeadline = styled(
  ({ expanded, onClick, ...rest }: TileInformationHeadlineProps) => {
    return <button onClick={onClick} aria-expanded={expanded} {...rest} />
  }
)`
  ${focusOutline};
  display: flex;
  align-items: center;
  gap: 16px;
  height: 52px;
  text-align: left;
`

const expandedTileInformationHeadlineTextCss = css`
  color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};
`

export const TileInformationHeadlineText = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.text.highContrast.on.dark};
  text-transform: uppercase;
`

export const TileInformationDescription = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};
  text-align: left;
  padding-top: 16px;
`

export const TileInformationContent = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
`

const expandedTileInformationBackgroundCss = css`
  background-color: ${o => o.theme.color.textOverlay.transparent.fill};

  ${untilMd} {
    max-height: 100%;
    min-height: 100%;
    height: 100%;
  }
  ${fromMd} {
    max-height: 100%;
    min-height: 72px;
    backdrop-filter: blur(10px);
  }
`

const expandedCss = css`
  ${ToggleHeadlineIcon} {
    background-color: ${o => o.theme.color.textOverlay.transparent.content.on.fill};

    &::before {
      background-color: ${o => o.theme.color.textOverlay.transparent.content.on.content};
    }

    &::after {
      background-color: transparent;
    }
  }
  ${TileInformationHeadlineText} {
    ${expandedTileInformationHeadlineTextCss}
  }
`

export const TileInformationBackground = styled.div<{ $expanded: boolean }>`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  max-height: 88px;
  min-height: 72px;
  bottom: 0;
  left: 0;
  background: transparent;
  backdrop-filter: none;
  z-index: 2;
  transition:
    background ${timingSlower} ease-in-out,
    min-height ${timingSlower} ease-in-out,
    max-height ${timingSlower} ease-in-out,
    backdrop-filter ${timingSlower} ease-in-out;

  ${o => (o.$expanded ? expandedTileInformationBackgroundCss : null)};
  ${o => (o.$expanded ? expandedCss : null)};
  ${withHoverSupport} {
    &:has(:focus-visible) {
      ${expandedTileInformationBackgroundCss};
      ${expandedCss};
    }
  }
`

export const Tile = styled(BaseTile)<{
  $aspectRatio: number
}>`
  position: relative;
  aspect-ratio: ${o => o.$aspectRatio};

  & > img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  ${withHoverSupport} {
    &:hover {
      ${TileInformationBackground} {
        ${expandedTileInformationBackgroundCss};
      }
      ${expandedCss};
    }
  }
`

export const TileContentWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  top: 0;
  padding: ${o => o.theme.spacing(4)};
  gap: ${o => o.theme.spacing(4)};
  width: 100%;
  box-sizing: border-box;
`

export const TileHeadline = styled(TypeStyle.HeadlineLarge)`
  color: ${o => o.theme.color.text.primary.on.background};
  text-align: left;
`

export const HeadlineWrapper = styled.div`
  padding-bottom: ${o => o.theme.spacing(5)};
  ${fromMd} {
    padding-bottom: ${o => o.theme.spacing(10)};
  }
`

export const OverlayColor = styled.div<{ $overlayOpacity: number | null }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${o =>
    o.$overlayOpacity === null ? 'transparent' : o.theme.color.fill.background};
  opacity: ${o => (o.$overlayOpacity === null ? '1' : o.$overlayOpacity)};
  box-sizing: border-box;
  top: 0;
  left: 0;
`

type TileInformation = {
  tileImage: string
  tileIndex: number
  headline: string
  description: string
  ctaLinkHref: string | null
  ctaText: string | null
}

export const TileInformation = ({
  tileImage,
  tileIndex,
  headline,
  description,
  ctaLinkHref,
  ctaText,
}: TileInformation) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const contentfulPageSectionContext = useContentfulPageSectionContext()
  return (
    <TileInformationBackground $expanded={isExpanded}>
      <TileInformationContent>
        <TileInformationHeadline
          expanded={isExpanded}
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          {...trackEvent({
            category: contentfulPageSectionContext.pageSectionAnalyticsName,
            action: `toggle expand on tile ${tileIndex}`,
            pageSectionName: contentfulPageSectionContext.pageSectionAnalyticsName,
            pageSectionIndex: contentfulPageSectionContext.pageSectionIndex,
            correspondingAsset: tileImage,
          })}
        >
          <ToggleHeadlineIcon />
          <TileInformationHeadlineText>{headline}</TileInformationHeadlineText>
        </TileInformationHeadline>
        <TileInformationDescription>{description}</TileInformationDescription>
        {ctaLinkHref && ctaText && (
          <>
            <Spacer height={24} width={1} />
            <CtaButton
              forwardedAs='a'
              href={ctaLinkHref}
              variant={'black-on-white-no-border'}
              {...trackEvent({
                category: contentfulPageSectionContext.pageSectionAnalyticsName,
                action: `click cta on tile ${tileIndex}`,
                pageSectionName: contentfulPageSectionContext.pageSectionAnalyticsName,
                pageSectionIndex: contentfulPageSectionContext.pageSectionIndex,
                correspondingAsset: tileImage,
              })}
            >
              {ctaText}
            </CtaButton>
          </>
        )}
      </TileInformationContent>
    </TileInformationBackground>
  )
}
