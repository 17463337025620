'use client'

import pluralize from 'pluralize'
import { ComponentProps, useMemo } from 'react'

import { TypeStyleHeadlineSmall, isUppercase } from '@syconium/little-miss-figgy'

import { gql } from '../../../../../__generated__/graphql/catalog/gql'
import { GetProductRecommendationsSectionQuery } from '../../../../../__generated__/graphql/catalog/graphql'
import { useFetchNostoPlacementData } from '../../../../../app/_hooks/useFetchNostoPlacementData'
import { useNextQuery } from '../../../../../app/_hooks/useNextQuery'
import { trackEvent } from '../../../../../lib/analytics'
import { QueryStatus } from '../../../../../lib/hooks/types'
import { degenderProductGroupTitle } from '../../../../../lib/utils/domain'
import { useTrackProductInteraction } from '../../../../_providers/TrackingProvider.client'
import { isValidNostoPlacementId } from '../../../chrome/scripts/NostoScript'
import { NextLink } from '../../../navigation/NextLink'
import {
  PriceRangeForProductGroup,
  derivePricingForProductGroup,
} from '../../../pricing/PriceRangeForProductGroup.client'
import {
  ProductTileColorCount,
  ProductTileColorName,
  ProductTileColors,
  ProductTileColorsSkeleton,
  ProductTileImage,
  ProductTileImageSkeleton,
  ProductTilePrice,
  ProductTilePriceSkeleton,
  ProductTileTitle,
  ProductTileTitleSkeleton,
} from '../../../tiles/ProductTile.client'
import { LIFTED_BUTTONS_DEFAULT_PIXELS, Tile, TilesLayout } from '../../../tiles/Tiles.client'
import {
  PageSection,
  PageSectionContent,
  sectionSideGutterFromMdAndExpanded,
  sectionSideGutterUntilMd,
} from '../../PageSection.client'
import { useContentfulPageSectionContext } from '../ContentfulPageSectionProvider.client'
import { ContentfulPageSectionProps } from '../ContentfulPageSections.server'

import {
  ContentfulPageCollectionSectionHeader,
  ContentfulPageCollectionSectionTitleWrapper,
} from './ContentfulPageCollectionSection.client'

type ContentfulPageProductRecommendationsSectionProps = ContentfulPageSectionProps & {
  contentfulSection: Extract<
    NonNullable<NonNullable<GetProductRecommendationsSectionQuery['pageViewSection']>>,
    { __typename: 'ProductRecommendationsSection' }
  >
  scrollBackLabel: ComponentProps<typeof TilesLayout>['scrollBackLabel']
  scrollForwardLabel: ComponentProps<typeof TilesLayout>['scrollForwardLabel']
  colorCountText: string
}

export const ContentfulPageProductRecommendationsSection = ({
  scrollBackLabel,
  scrollForwardLabel,
  contentfulSection,
  pageSectionIndex,
  colorCountText,
}: ContentfulPageProductRecommendationsSectionProps) => {
  const sectionContext = useContentfulPageSectionContext()

  const {
    nostoPlacementId: nostoPlacementIdProp,
    productCountLimit: productCountLimitProp,
    useNostoPlacementTitle,
    title: titleProp,
    hideColors,
    hidePrice,
    name: contentfulSectionName,
    titleAlignmentDesktop: titleAlignmentDesktopProp,
    titleAlignmentMobile: titleAlignmentMobileProp,
  } = contentfulSection

  const nostoPlacementId = isValidNostoPlacementId(nostoPlacementIdProp)
    ? nostoPlacementIdProp
    : 'frontpage-nosto-1'

  const titleAlignmentDesktop = titleAlignmentDesktopProp ?? 'left'
  const titleAlignmentMobile = titleAlignmentMobileProp ?? 'left'
  const productCountLimit = productCountLimitProp ?? 48

  const {
    data: {
      productHandles: nostoRecommendedProductHandles,
      title: nostoTitle,
      resultId: nostoResultId,
    },
    status: nostoStatus,
  } = useFetchNostoPlacementData(nostoPlacementId)

  const titleText = useNostoPlacementTitle && nostoTitle ? nostoTitle : titleProp

  const { data: productsData, status: productsStatus } = useNextQuery(
    gql(`
      query GetPageRecommendationsSectionProducts($productHandles: [String!]) {
        optimizedProducts(handles: $productHandles) {
          nodes {
            id
            shopifyId
            handle
            category
            colorInfo {
              handle
              name
              rawName
            }
            images {
              nodes {
                source
              }
            }
            productGroup {
              handle
              title
              numColors
              priceRange {
                min
                max
              }
            }
            defaultVariant {
              priceDetails {
                price {
                  amount
                  currency
                }
                discountPrice {
                  amount
                }
              }
            }
          }
        }
      }
    `),
    {
      variables: {
        productHandles: nostoRecommendedProductHandles?.slice(0, productCountLimit) ?? [],
      },
      skip: nostoStatus !== 'resolved',
    }
  )

  const status = useMemo<Exclude<QueryStatus, 'idle'>>(() => {
    if (nostoStatus === 'pending' || productsStatus === 'pending') return 'pending'
    if (nostoStatus === 'rejected' || productsStatus === 'rejected') return 'rejected'
    return 'resolved'
  }, [nostoStatus, productsStatus])

  const tilesPerViewportWidth = useMemo(() => {
    return {
      sm: 2,
      md: 4,
    }
  }, [])
  const tilesLayoutChoices = useMemo(() => {
    return {
      sm: 'slider',
      md: 'slider',
    } as const
  }, [])
  const tilesGutterChoices = useMemo(() => {
    return {
      sm: sectionSideGutterUntilMd,
      md: sectionSideGutterFromMdAndExpanded,
    } as const
  }, [])
  const skeletonTiles = useMemo(() => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [])
  const liftedButtons = !hideColors || !hidePrice

  const products = useMemo(() => {
    const products = (productsData?.optimizedProducts?.nodes ?? []).map(productData => {
      const productGroup = productData.productGroup
      const product = {
        category: productData.category,
        colorDisplayName: productData.colorInfo?.name,
        colorRawName: productData.colorInfo?.rawName,
        colorCount: productGroup.numColors,
        pricing: productData.defaultVariant.priceDetails?.price
          ? derivePricingForProductGroup({
              products: [
                {
                  priceRange: productGroup.priceRange,
                  defaultVariant: {
                    price: productData.defaultVariant.priceDetails.price.amount,
                    discountPrice: productData.defaultVariant.priceDetails.discountPrice?.amount,
                  },
                },
              ],
            })
          : undefined,
        handle: productData.handle ?? '',
        id: productData.id,
        image: productData.images?.nodes[0]?.source,
        productGroupHandle: productGroup.handle,
        shopifyId: productData.shopifyId,
        title: productGroup.title ? degenderProductGroupTitle(productGroup.title) : '',
      }
      return product
    })

    return products
  }, [productsData?.optimizedProducts?.nodes])

  const { trackProductInteraction } = useTrackProductInteraction()

  return (
    <PageSection>
      {titleText && (
        <PageSectionContent gutterSize='expanded'>
          <ContentfulPageCollectionSectionHeader>
            <ContentfulPageCollectionSectionTitleWrapper
              $textAlign={{
                sm: titleAlignmentMobile,
                md: titleAlignmentDesktop,
              }}
            >
              <TypeStyleHeadlineSmall isUppercase={isUppercase(titleText)}>
                {titleText}
              </TypeStyleHeadlineSmall>
            </ContentfulPageCollectionSectionTitleWrapper>
          </ContentfulPageCollectionSectionHeader>
        </PageSectionContent>
      )}

      <PageSectionContent gutterSize='none'>
        <TilesLayout
          scrollBackLabel={scrollBackLabel}
          scrollForwardLabel={scrollForwardLabel}
          liftButtonsPixels={liftedButtons ? LIFTED_BUTTONS_DEFAULT_PIXELS : 0}
          layout={tilesLayoutChoices}
          visibleTiles={tilesPerViewportWidth}
          gutters={tilesGutterChoices}
        >
          {status === 'pending'
            ? skeletonTiles.map((_, index) => {
                return (
                  <Tile key={index}>
                    <ProductTileImageSkeleton aspectRatio={'slider'} />
                    <ProductTileTitleSkeleton />
                    {!hideColors ? <ProductTileColorsSkeleton /> : null}
                    {!hidePrice ? <ProductTilePriceSkeleton /> : null}
                  </Tile>
                )
              })
            : products.map((product, index) => {
                if (!product.image) return null

                const productUrl =
                  product.category === 'Product Kit'
                    ? `/kits/${product.productGroupHandle}?nosto=${nostoResultId}`
                    : `/products/${product.productGroupHandle}?color=${product.colorRawName}&nosto=${nostoResultId}`

                return (
                  <Tile
                    key={`${product.id}${index}`}
                    {...trackEvent({
                      category: 'product-carousel-tile-nosto',
                      customAttributes: {
                        'data-heap-placement-id': nostoPlacementId,
                        'data-heap-parent-title': titleText ?? undefined,
                        'data-heap-section-name': contentfulSectionName ?? undefined,
                        'data-heap-contentful-name': contentfulSectionName ?? undefined,
                      },
                      action: 'click tile',
                      label: `tile-${index + 1}`,
                      value: product.title,
                      pageSectionIndex: pageSectionIndex,
                      pageSectionName: sectionContext.pageSectionAnalyticsName,
                      correspondingAsset: product.image,
                    })}
                  >
                    <NextLink
                      href={productUrl}
                      onClick={() => {
                        trackProductInteraction({
                          handle: product.handle,
                          id: product.id,
                          shopifyId: product.shopifyId,
                        })
                      }}
                    >
                      <ProductTileImage
                        alt={product.title}
                        loading='lazy'
                        src={product.image}
                        aspectRatio={'slider'}
                        widths={{
                          unit: 'vw',
                          sm: 100 / tilesPerViewportWidth.sm,
                          md: 100 / tilesPerViewportWidth.md,
                        }}
                      />
                      <ProductTileTitle>{product.title}</ProductTileTitle>
                      {!hideColors ? (
                        <ProductTileColors>
                          {product.colorRawName !== 'No Color' && (
                            <ProductTileColorName>{product.colorDisplayName}</ProductTileColorName>
                          )}
                          <ProductTileColorCount>
                            {product.colorCount} {pluralize(colorCountText, product.colorCount)}
                          </ProductTileColorCount>
                        </ProductTileColors>
                      ) : null}
                      {!hidePrice && product.pricing ? (
                        <ProductTilePrice>
                          <PriceRangeForProductGroup
                            {...product.pricing}
                            upToPercentageOff={null}
                            percentageOff={null}
                          />
                        </ProductTilePrice>
                      ) : null}
                    </NextLink>
                  </Tile>
                )
              })}
        </TilesLayout>
      </PageSectionContent>
    </PageSection>
  )
}
