import { useStoreState } from '@ariakit/react'
import React, { ComponentProps, useCallback } from 'react'
// @ts-ignore implicit-any
import ModalVideo from 'react-modal-video'
import { useMedia } from 'react-use'
import styled, { useTheme } from 'styled-components'

import { NextDialog, NextDialogBackdrop, useNextDialogContext } from './NextDialog.client'

const ModalVideoWrapper = styled.div`
  height: 100%;
  width: 100%;

  .modal-video,
  .modal-video-movie-wrap iframe {
    background-color: #000;
  }

  .modal-video-body {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: none;
  }

  .modal-video-movie-wrap {
    position: static;
  }

  .modal-video-close-btn {
    right: 24px;
    top: 24px;
    height: 24px;
    width: 24px;
  }

  .modal-video-close-btn::before,
  .modal-video-close-btn::after {
    margin-top: 0;
  }
`

type NextVideoDialogBackdropProps = ComponentProps<typeof NextDialogBackdrop>

export const NextVideoDialogBackdrop = styled((props: NextVideoDialogBackdropProps) => {
  return <NextDialogBackdrop {...props} />
})`
  padding: 0px;
  &[data-enter] {
    background-color: #000000;
  }
`

type NextVideoDialogProps = ComponentProps<typeof NextDialog> & {
  videoPlatform: 'vimeo' | 'youtube'
  videoId: string
  videoIdMobile?: string | null
}

export const NextVideoDialog = styled(
  ({ videoPlatform, videoId, videoIdMobile, ...props }: NextVideoDialogProps) => {
    const { device } = useTheme()
    const isDesktop = useMedia(`(min-width: ${device.breakpoint.md})`, false)

    const dialogContext = useNextDialogContext()
    const dialogState = useStoreState(dialogContext)
    const isOpen = dialogState?.open
    const onClose = dialogContext?.hide
    const onVideoAreaKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === ' ' || event.key === 'Enter') {
          if (
            event.target instanceof HTMLElement &&
            event.target.getAttribute('class')?.includes('modal-video-close-btn')
          ) {
            event.preventDefault()
            dialogContext?.hide()
          }
        }
      },
      [dialogContext]
    )

    const getVideoId = () => {
      if (videoIdMobile && !isDesktop) {
        return videoIdMobile
      }
      return videoId
    }

    return (
      <NextDialog backdrop={NextVideoDialogBackdrop} {...props}>
        <ModalVideoWrapper onKeyDownCapture={onVideoAreaKeyDown}>
          <ModalVideo
            isOpen={isOpen}
            onClose={onClose}
            videoId={getVideoId()}
            channel={videoPlatform}
            allowFullScreen
            autoplay
          />
        </ModalVideoWrapper>
      </NextDialog>
    )
  }
)`
  box-sizing: border-box;
  width: 100%;
  min-width: unset;
  max-width: unset;
  height: 100%;
  height: min(var(--dialog-viewport-height), 100dvh);
  min-height: unset;
  max-height: unset;
  border-radius: 0px;
  margin: 0px;
  opacity: 1;
`
