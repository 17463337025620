'use client'

import styled from 'styled-components'

import {
  ResponsiveImage,
  TypeStyleHeadlineExtraSmall,
  TypeStyleHeadlineMedium,
  fromMd,
  untilMd,
} from '@syconium/little-miss-figgy'

import { PageSectionContent } from '../../PageSection.client'

export const ContentfulPageVideoSectionWrapper = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 360px;
  position: relative;

  ${fromMd} {
    min-height: 550px;
  }
`

export const ContentfulPageVideoSectionContent = styled(PageSectionContent)<{
  $textColor: string
}>`
  align-items: center;
  display: flex;
  box-sizing: border-box;
  color: ${o => o.$textColor};
  flex-direction: column;
  line-height: 1.5;
  padding: 16px 24px;
  text-align: center;
  width: 100%;
  z-index: 1;

  ${fromMd} {
    padding: 24px 40px;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

export const ContentfulPageVideoSectionTitleImage = styled(ResponsiveImage)`
  height: 100px;
  max-width: 100%;
  object-fit: contain;
`

export const ContentfulPageVideoSectionTitle = styled(TypeStyleHeadlineMedium)<{
  $textContentMaxWidthDesktop: number | null | undefined
  $textContentMaxWidthMobile: number | null | undefined
}>`
  margin: 10px auto 0px;

  ${o => {
    const smMaxWidth = o.$textContentMaxWidthMobile ? `${o.$textContentMaxWidthMobile}px` : 'unset'
    const mdMaxWidth = o.$textContentMaxWidthDesktop ? `${o.$textContentMaxWidthDesktop}px` : '80%'

    return `
      ${untilMd} {
        max-width: ${smMaxWidth};
      }

      ${fromMd} {
        max-width: ${mdMaxWidth};
      }
    `
  }}
`

export const ContentfulPageVideoSectionSubtitle = styled(TypeStyleHeadlineExtraSmall)<{
  $textContentMaxWidthDesktop: number | null | undefined
  $textContentMaxWidthMobile: number | null | undefined
}>`
  margin: 10px auto 0;

  ${o => {
    const smMaxWidth = o.$textContentMaxWidthMobile ? `${o.$textContentMaxWidthMobile}px` : 'unset'
    const mdMaxWidth = o.$textContentMaxWidthDesktop ? `${o.$textContentMaxWidthDesktop}px` : '80%'

    return `
      ${untilMd} {
        max-width: ${smMaxWidth};
      }

      ${fromMd} {
        max-width: ${mdMaxWidth};
      }
    `
  }}
`

export const ContentfulPageVideoSectionBackground = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  & > img,
  & > picture > img,
  & > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const ContentfulPageVideoSectionActions = styled.div`
  display: flex;
  margin: ${o => o.theme.spacing(6, 2, 0)};
  gap: ${o => o.theme.spacing(3)};
`
